export class CreateIndexSchemaSheet {
  schemaId: number;
  name: string;
  dataKey: string;
  constructor(schemaId: number, name: string, dataKey: string) {
    this.schemaId = schemaId;
    this.name = name;
    this.dataKey = dataKey;
  }
}
