import { Component, EventEmitter, Output } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { DeleteMediaFile, SetMediaFile } from '../../_model/_shared/media-file';
import { EmitEvent, EventBusService, Events } from '../../_service/event-bus.service';
import {ContainerService} from '../../_service/container.service';
import {AovContainer} from '../../_model/aov/aov-container';
import {MyNusachContainer} from '../../_model/mynusach/mynusach-container';
import {FileType, MediaType} from "../../_model/_shared/enums";


@Component({
  selector: 'formly-field-sheet-music',
  styleUrls: ['./video-type.scss'],
  template: `
    <legend *ngIf="to.label">{{ to.label }}</legend>
    <input #videoFileInput
           class="file-input"
           type="file"
           accept=".mp4,.m4v"
           (change)="onChange($event)">
    <button  *ngIf="!model.videoFile || !model.videoFile.identifier"
             mat-flat-button
             type="button"
             color="primary"
             class="upload-btn"
             (click)="videoFileInput.click()">Choose File
      <mat-icon>attach_file</mat-icon>
    </button>
    <button *ngIf="model.videoFile && model.videoFile.identifier" mat-flat-button
            type="button"
            color="warn"
            class="delete-btn"
            (click)="delete(model.videoFile)">Delete File
      <mat-icon>delete</mat-icon>
    </button>
    <br>
    <mat-form-field *ngIf="model.videoFile" appearance="outline" style="display:block;width:70%">
      <mat-label>{{props.label}}</mat-label>
      <input matInput [value]="model.videoFile.name" (change)="onFileNameChange($event)">
      <div class="alert alert-danger" role="alert" *ngIf="showError && formControl.errors">
        <formly-validation-message [field]="field"></formly-validation-message>
      </div>
    </mat-form-field>
    <div class="file-upload"
         *ngIf="!model || !model.videoFile || !model.videoFile.name || '' == model.videoFile.name">{{ "No file selected yet." }}
    </div>
    <mat-divider></mat-divider><br>
<!--    <input #fileNameInput-->
<!--           class="input"-->
<!--           type="text"-->
<!--           (change)="onFileNameChange($event)">-->
<!--    <div class="file-upload" *ngIf="model.videoFile">{{ model.videoFile.name}}</div>-->
<!--    <div class="file-upload" *ngIf="!model.videoFile">{{ "No file selected yet." }}</div>-->
<!--    <mat-divider></mat-divider><br>-->
  `,
})
export class FormlyVideoFileField extends FieldType {
  private oldFile: File;
  constructor(public eventService: EventBusService, public containerService: ContainerService) {
    super();
  }
  @Output() onDeleteMediaFile = new EventEmitter<DeleteMediaFile>();

  @Output() onMediaFileSelected = new EventEmitter<SetMediaFile>();

  alertError(message: string): void {
    this.eventService.emit(new EmitEvent(Events.onAlertError, message));
  }

  delete(mediaFile): void {
    const deleteMediaFile = new DeleteMediaFile(mediaFile, this.oldFile);
    this.eventService.emit(new EmitEvent(Events.onDeleteMediaFile, deleteMediaFile));
  }
  onFileNameChange(event): void {
    if (!this.model.videoFile.originalFileName){
      this.model.videoFile.originalFileName = this.model.videoFile.name;
    }
    this.model.videoFile.name = event.target.value;
  }

  onChange(event): void {
    const file: File = event.target.files[0];
    const formAction = event.target.formAction;
    const allowedExtensions = /(\.mp4|\.m4v)$/i;
    if (!allowedExtensions.exec(file.name)){
      this.alertError('Please upload file having extensions .mp4/.m4v only.');
    }
    else {
      const container = this.containerService.getContainer();
      let masterData = null;
      if (container.identifier.startsWith('AOV')){
        masterData = (container as AovContainer).aovBase;
      }
      if (container.identifier.startsWith('MYN')){
        masterData = (container as MyNusachContainer).cantor;
      }

      this.model.videoFile = {};
      this.model.videoFile.indexType = this.eventService.getIndexTypeViaFormAction(formAction);
      this.model.videoFile.indexIdentifier = this.eventService.getIdentifierViaFormAction(formAction);
      this.model.videoFile.identifier;
      this.model.videoFile.mediaFileCredit;
      this.model.videoFile.description;
      this.model.videoFile.fileType = FileType.MP4;
      this.model.videoFile.mediaType = MediaType.VIDEO;
      this.model.videoFile.originalFileName = file.name;
      this.model.videoFile.name = file.name;
      this.model.videoFile.name = masterData.lastName
          + ',' + masterData.firstName
          + ' - ' + this.model.songTitle
          + ' - ' + this.model.videoFile.mediaType;
      const setMediaFile = new SetMediaFile(this.model.videoFile, file);
      this.eventService.emit(new EmitEvent(Events.onMediaFileSelected, setMediaFile));
      this.oldFile = file;
    }
  }
}
