import { Component, EventEmitter, Output } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { SetMediaFile, DeleteMediaFile } from '../../_model/_shared/media-file';
import { EmitEvent, EventBusService, Events } from '../../_service/event-bus.service';
import { ContainerService } from '../../_service/container.service';
import { AovContainer } from '../../_model/aov/aov-container';
import { MyNusachContainer } from '../../_model/mynusach/mynusach-container';
import {FileType, MediaType} from "../../_model/_shared/enums";

@Component({
  selector: 'formly-field-piano-file',
  styleUrls: ['./piano-file-type.scss'],
  template: `
    <legend *ngIf="props.label">{{ props.label }}</legend>
    <input #pianoFileInput
           class="file-input"
           type="file"
           accept=".mp3,.m4a,.mp4"
           (change)="onChange($event)" >
    <button *ngIf="!model.pianoFile || !model.pianoFile.identifier"
            mat-flat-button
            type="button"
            color="primary"
            class="upload-btn"
            (click)="pianoFileInput.click()">Choose File
      <mat-icon>attach_file</mat-icon>
    </button>
    <button *ngIf="model.pianoFile && model.pianoFile.identifier" mat-flat-button
            type="button"
            color="warn"
            class="delete-btn"
            (click)="delete(model.pianoFile)">Delete File
      <mat-icon>delete</mat-icon>
    </button>
    <br>
    <mat-form-field *ngIf="model.pianoFile" appearance="outline" style="display:block;width:70%">
      <mat-label>{{props.label}}</mat-label>
      <input matInput [value]="model.pianoFile.name" (change)="onFileNameChange($event)">
      <div class="alert alert-danger" role="alert" *ngIf="showError && formControl.errors">
        <formly-validation-message [field]="field"></formly-validation-message>
      </div>
    </mat-form-field>
    <div class="file-upload"
         *ngIf="!model || !model.sheetMusic || !model.sheetMusic.name || '' == model.sheetMusic.name">{{ "No file selected yet." }}
    </div>
    <mat-divider></mat-divider><br>
<!--    <div class="file-upload" *ngIf="model.pianoFile">{{ model.pianoFile.name || "No file selected yet." }}</div>-->
<!--    <div class="file-upload" *ngIf="!model.pianoFile">{{ "No file selected yet." }}</div>-->
<!--    <mat-divider></mat-divider><br>-->
  `,
})
export class FormlyPianoFileField extends FieldType{
  private oldFile: File;
  constructor(public eventService: EventBusService, public containerService: ContainerService) {
    super();
  }

  @Output() onDeleteMediaFile = new EventEmitter<DeleteMediaFile>();

  @Output() onMediaFileSelected = new EventEmitter<SetMediaFile>();

  alertError(message: string): void {
    this.eventService.emit(new EmitEvent(Events.onAlertError, message));
  }

  delete(mediaFile): void {
    const deleteMediaFile = new DeleteMediaFile(mediaFile, this.oldFile);
    this.eventService.emit(new EmitEvent(Events.onDeleteMediaFile, deleteMediaFile));
  }

  onFileNameChange(event): void {
    if (!this.model.pianoFile.originalFileName){
      this.model.pianoFile.originalFileName = this.model.pianoFile.name;
    }
    this.model.pianoFile.name = event.target.value;
  }

  onChange(event): void {
    const file: File = event.target.files[0];
    const formAction = event.target.formAction;
    const allowedExtensions = /(\.mp3|\.m4a|\.mp4)$/i;
    if (!allowedExtensions.exec(file.name)) {
      this.alertError('Please upload file having extensions .mp3 only.');
    }
    else {
      const container = this.containerService.getContainer();
      let masterData = null;
      if (container.identifier.startsWith('AOV')){
        masterData = (container as AovContainer).aovBase;
      }
      if (container.identifier.startsWith('MYN')){
        masterData = (container as MyNusachContainer).cantor;
      }
      this.model.pianoFile = {};
      this.model.pianoFile.indexType = this.eventService.getIndexTypeViaFormAction(formAction);
      this.model.pianoFile.indexIdentifier = this.eventService.getIdentifierViaFormAction(formAction);
      // this.model.pianoFile.identifier;
      // this.model.pianoFile.mediaFileCredit;
      // this.model.pianoFile.description;
      this.model.pianoFile.fileType = FileType.MP3;
      this.model.pianoFile.mediaType = MediaType.PIANOFILE;
      this.model.pianoFile.originalFileName = file.name;
      this.model.pianoFile.name = file.name;
      this.model.pianoFile.name = masterData.lastName
          + ',' + masterData.firstName
          + ' - ' + this.model.songTitle
          + ' - ' + this.model.pianoFile.mediaType;
      const setMediaFile = new SetMediaFile(this.model.pianoFile, file);
      this.eventService.emit(new EmitEvent(Events.onMediaFileSelected, setMediaFile));
      this.oldFile = file;
    }
  }
}
