<div class="dialog-panel">
  <h1 mat-dialog-title class="mat-dialog-title">{{data.title}}</h1>
  <div mat-dialog-content class="mat-dialog-content">{{data.message}}</div>
  <div *ngIf="data.type === ALERT_TYPE_ERROR" mat-dialog-actions class="mat-dialog-actions">
    <button mat-button (click)="okClick()">OK</button>
  </div>
  <div *ngIf="data.type === ALERT_TYPE_CONFIRM" mat-dialog-actions class="mat-dialog-actions">
    <button mat-button (click)="okClick()">Ja</button>
    <button mat-button (click)="cancelClick($event)">Nein</button>
  </div>
</div>

