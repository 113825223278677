export class AlertModel
{
  id?: string;
  title?: string;
  type: AlertType;
  message?: string;
  autoClose?: boolean;
  keepAfterRouteChange?: boolean;
  fade?: boolean;
  confirmCallback?: () => void;
  callbackParam?: object;

  constructor(init?: Partial<AlertModel>) {
    Object.assign(this, init);
  }
}

export enum AlertType {
  Success,
  Error,
  Info,
  Warning,
  Confirm
}
