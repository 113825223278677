import { AovBase } from './aov-base';
import { Composition } from '../_shared/composition';
import { AovBio } from './aov-bio';
import { AovInfo } from './aov-info';

export class AovContainer {
  identifier: string;
  aovBase: AovBase;
  compositionList: Composition[];
  info: AovInfo;
  biography: AovBio;
  constructor(identifier: string, aovBase: AovBase, compositionList: Composition[], info: AovInfo, biography: AovBio) {
    this.identifier = identifier;
    this.aovBase = aovBase;
    this.compositionList = compositionList ?? [];
    this.info = info ?? new AovInfo();
    this.biography = biography ?? new AovBio();
  }

  public static fromResponse(data: AovContainer): AovContainer {
    if (null == data){
      return null;
    }
    return new AovContainer(
      data.identifier,
      AovBase.fromResponse(data.aovBase),
      data.compositionList,
      data.info,
      data.biography
    );
  }
}
