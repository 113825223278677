import {Component, EventEmitter, Output} from '@angular/core';
import {FieldType} from '@ngx-formly/core';
import {DeleteMediaFile, SetMediaFile} from '../../_model/_shared/media-file';
import {EmitEvent, EventBusService, Events} from '../../_service/event-bus.service';
import {ContainerService} from '../../_service/container.service';
import {AovContainer} from '../../_model/aov/aov-container';
import {MyNusachContainer} from '../../_model/mynusach/mynusach-container';
import {FileType, MediaType} from "../../_model/_shared/enums";


@Component({
  selector: 'formly-field-sheet-music',
  styleUrls: ['./sheet-music-type.scss'],
  template: `
    <legend *ngIf="to.label">{{ to.label }}</legend>
    <input #sheetMusicFileInput
           class="file-input"
           type="file"
           accept=".pdf"
           (change)="onChange($event)">
    <button  *ngIf="!model.sheetMusic || !model.sheetMusic.identifier"
            mat-flat-button
            type="button"
            color="primary"
            class="upload-btn"
            (click)="sheetMusicFileInput.click()">Choose File
      <mat-icon>attach_file</mat-icon>
    </button>
    <button *ngIf="model.sheetMusic && model.sheetMusic.identifier" mat-flat-button
            type="button"
            color="warn"
            class="delete-btn"
            (click)="delete(model.sheetMusic)">Delete File
      <mat-icon>delete</mat-icon>
    </button>
    <br>
    <mat-form-field *ngIf="model.sheetMusic" appearance="outline" style="display:block;width:70%">
      <mat-label>{{props.label}}</mat-label>
      <input matInput [value]="model.sheetMusic.name" (change)="onFileNameChange($event)">
      <div class="alert alert-danger" role="alert" *ngIf="showError && formControl.errors">
        <formly-validation-message [field]="field"></formly-validation-message>
      </div>
    </mat-form-field>
    <div class="file-upload"
         *ngIf="!model || !model.sheetMusic || !model.sheetMusic.name || '' == model.sheetMusic.name">{{ "No file selected yet." }}
    </div>
    <mat-divider></mat-divider><br>
<!--    <div class="file-upload" *ngIf="model.sheetMusic">{{ model.sheetMusic.name}}</div>-->
<!--    <div class="file-upload" *ngIf="!model.sheetMusic">{{ "No file selected yet." }}</div>-->
<!--    <mat-divider></mat-divider><br>-->
  `,
})
export class FormlySheetMusicFileField extends FieldType {
  private oldFile: File;
  constructor(public eventService: EventBusService, public containerService: ContainerService) {
    super();
  }
  @Output() onDeleteMediaFile = new EventEmitter<DeleteMediaFile>();

  @Output() onMediaFileSelected = new EventEmitter<SetMediaFile>();

  alertError(message: string): void {
    this.eventService.emit(new EmitEvent(Events.onAlertError, message));
  }

  delete(mediaFile): void {
    const deleteMediaFile = new DeleteMediaFile(mediaFile, this.oldFile);
    this.eventService.emit(new EmitEvent(Events.onDeleteMediaFile, deleteMediaFile));
  }

  onFileNameChange(event): void {
    if (!this.model.sheetMusic.originalFileName){
      this.model.sheetMusic.originalFileName = this.model.sheetMusic.name;
    }
    this.model.sheetMusic.name = event.target.value;
  }

  onChange(event): void{
    const file: File = event.target.files[0];
    const formAction = event.target.formAction;
    const allowedExtensions = /(\.pdf)$/i;
    if (!allowedExtensions.exec(file.name)) {
      this.alertError('Please upload file having extensions .pdf only.');
    }
    else {
      const container = this.containerService.getContainer();
      let masterData = null;
      if (container.identifier.startsWith('AOV')){
        masterData = (container as AovContainer).aovBase;
      }
      if (container.identifier.startsWith('MYN')){
        masterData = (container as MyNusachContainer).cantor;
      }
      this.model.sheetMusic = {};
      this.model.sheetMusic.indexType = this.eventService.getIndexTypeViaFormAction(formAction);
      this.model.sheetMusic.indexIdentifier = this.eventService.getIdentifierViaFormAction(formAction);
      // this.model.sheetMusic.identifier;
      // this.model.sheetMusic.mediaFileCredit;
      // this.model.sheetMusic.description;
      this.model.sheetMusic.fileType = FileType.PDF;
      this.model.sheetMusic.mediaType = MediaType.SHEETMUSIC;
      this.model.sheetMusic.originalFileName = file.name;
      this.model.sheetMusic.name = file.name;
      this.model.sheetMusic.name = masterData.lastName
          + ',' + masterData.firstName
          + ' - ' + this.model.songTitle
          + ' - ' + this.model.sheetMusic.mediaType;
      const setMediaFile = new SetMediaFile(this.model.sheetMusic, file);
      this.eventService.emit(new EmitEvent(Events.onMediaFileSelected, setMediaFile));
      this.oldFile = file;
    }
  }
}
