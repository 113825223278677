<h1 mat-dialog-title>Add New Entry</h1>
<div mat-dialog-content>
  <p>Create New {{data.dialogType}}</p>
  <mat-form-field appearance="fill">
    <mat-label>Name</mat-label>
    <input matInput [(ngModel)]="data.name">
  </mat-form-field><br>
  <div *ngIf="data.dialogType === 'SHEET'">
    <mat-form-field appearance="fill">
      <mat-label>data key</mat-label>
      <input matInput [(ngModel)]="data.dataKey">
    </mat-form-field><br>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button (click)="save(data)" cdkFocusInitial>Save</button>
</div>


