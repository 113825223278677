export class AovBio
{
  private orientationList: string[] = [];
  private ordinationList: string[] = [];
  private educationList: string[] = [];
  private honorsYear: string[] = [];
  private publicationsAuthorEditor: string[] = [];
  private academicAppointments: string[] = [];
  private foundings: string[] = [];
  private jewishCommunal: string[] = [];
  private otherCareer: string[] = [];
  constructor() {
  }
}
