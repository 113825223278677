export class AovInfo
{
  private websites: string[] = [];
  private musicPublications: string[] = [];
  private organizationalMemberships: string[] = [];
  private musicalInfluences: string[] = [];
  private additionalInfluences: string[] = [];
  private majorConcertsAndAppearances: string[] = [];

  constructor() {

  }
}
