import { Component, OnInit } from '@angular/core';
import {first} from 'rxjs/operators';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../_service/auth.service';
import {AlertService} from '../_service/alert.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  form: UntypedFormGroup;
  public loginInvalid = false;
  private loading = false;
  private submitted = false;

  private formSubmitAttempt = false;
  private readonly returnUrl: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private alertService: AlertService
  ) {
    this.returnUrl = '/aov';
    this.form = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    if (null != this.authService.getToken){
      this.router.navigateByUrl(this.returnUrl);
    }
  }

  // convenience getter for easy access to form fields
  get controls(): any {
    return this.form.controls;
  }

  onSubmit(): void {
    // reset alerts on submit
    this.alertService.clear();

    this.loginInvalid = false;
    this.formSubmitAttempt = false;

    if (this.form.valid) {
      this.loading = true;
      this.authService.login(this.controls.username.value, this.controls.password.value)
        .pipe(first())
        .subscribe({
          next: () => {
            // get return url from query parameters or default to home page
            // let roles = this.authService.getUserRoles();
            const returnUrl = this.route.snapshot.queryParams.returnUrl || this.returnUrl;
            this.router.navigateByUrl(returnUrl);
          },
          error: (error: any) => {
            this.loginInvalid = true;
            this.alertService.error(error);
            this.loading = false;
          }
        });
    }
    else {
      this.formSubmitAttempt = true;
    }
  }
}
