import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertComponent } from './alert.component';
import { AngularMaterialModule} from '../angular-material.module';
import { ReactiveFormsModule} from '@angular/forms';
import { DialogComponent } from '../components/dialog/dialog.component';
import { AlertService } from '../_service/alert.service';


@NgModule({
  imports: [CommonModule, AngularMaterialModule, ReactiveFormsModule],
  declarations: [AlertComponent, DialogComponent],
  exports: [AlertComponent],
  providers: [ { provide: AlertService } ]
})
export class AlertModule { }
