import {Component, ViewChild} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {SchemaDataTableComponent} from '../schema-data-table/schema-data-table.component';
import {Router} from '@angular/router';
import {UploadService} from '../../file-upload.service';
import {SchemaCreateComponent} from '../schema-create/schema-create.component';

@Component({
  selector: 'app-schema-master',
  templateUrl: './schema-master.component.html',
  styleUrls: ['./schema-master.component.scss']
})
export class SchemaMasterComponent {
  @ViewChild(SchemaDataTableComponent) dataTable: SchemaDataTableComponent;
  schemaId: string;
  name: string;

  private returnUrl = '/schema';
  constructor(private router: Router,
              private uploadService: UploadService,
              public dialog: MatDialog) { }

  ngOnInit(): void {
    this.uploadService.resetFields();
  }
  openDialog(): void {
    // this.dataTable.ngOnInit();
    // todo: maybe implement reload datatable's content before creation & insertion of new item
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = true;
    dialogConfig.panelClass = ['aov-create-dialog-panel'];

    dialogConfig.direction = 'rtl';
    // dialogConfig.width = '800px';
    dialogConfig.data = { dialogType: 'SCHEMA' };

    const dialogRef = this.dialog.open(SchemaCreateComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

}
