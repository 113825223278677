import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {AdminService} from '../../../_service/admin.service';
import {MatTabGroup} from '@angular/material/tabs';
import {FormlyFieldConfig, FormlyFormOptions} from '@ngx-formly/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormlyJsonschema} from '@ngx-formly/core/json-schema';
import {AovContainer} from '../../../_model/aov/aov-container';
import {UploadService} from '../../file-upload.service';
import {UtilsService} from '../../../_service/utils.service';
import {DeleteMediaFile, SetMediaFile} from '../../../_model/_shared/media-file';
import {AlertService} from '../../../_service/alert.service';
import {IDetailComponent} from '../../detail.component.interface';
import {EventBusService, Events} from '../../../_service/event-bus.service';
import {Subscription} from 'rxjs';
import {Aov} from '../../../_model/EntityType';
import {IndexSchema} from '../../../_model/schema/IndexSchema';
import {IndexSchemaSheet} from '../../../_model/schema/sheet/IndexSchemaSheet';
import {JSONSchema7} from 'json-schema';
import {ContainerService} from '../../../_service/container.service';
import {IndexType, MediaType} from "../../../_model/_shared/enums";

@Component({
  selector: 'app-aov-detail',
  templateUrl: './aov-detail.component.html',
  styleUrls: ['./aov-detail.component.scss']
})
export class AovDetailComponent implements OnInit, OnDestroy, IDetailComponent
{
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;

  form: FormGroup;
  options: FormlyFormOptions;
  fields: FormlyFieldConfig[];
  model: object = { };
  tabList = [];

  private id: any;
  private dataModel: AovContainer;
  private formFieldConfig: object = { };
  private tabListMap: object = {
    master: 'aovBase',
    media: 'compositionList',
    info: 'info',
    biography: 'biography'
  };

  isMaster = true;
  isLoading: boolean;
  isUpdate: boolean;
  isValid = false;
  schemaArray: string[] = [];
  adminService: AdminService;
  alertService: AlertService;
  activeTab: any;

  eventSubscriptions: Subscription[];
  type: string;

  constructor(adminService: AdminService,
              alertService: AlertService,
              public eventService: EventBusService,
              public containerService: ContainerService,
              private router: Router,
              private route: ActivatedRoute,
              private formlyJsonschema: FormlyJsonschema,
              private uploadService: UploadService,
              private utils: UtilsService) {
    this.alertService = alertService;
    this.adminService = adminService;
    this.adminService.isLoading = true;
    this.route.url.subscribe(() => {
      if (null != route.snapshot.params.id) {
        this.isUpdate = true;
        this.id = route.snapshot.params.id;
      }
    });
  }

  ngOnDestroy(): void {
    this.eventSubscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  ngOnInit(): void {
    this.eventSubscriptions = [];
    const self = this;
    // show alert
    // todo: add alertType to also show success, info, warn messages
    this.eventSubscriptions[0] = this.eventService.on(Events.onAlertError, (alert) => {
      if (alert.indexType === IndexType.AOV) {
        self.alertService.error(alert.message);
      }
    });
    // delete mediaFile
    this.eventSubscriptions[1] = this.eventService.on(Events.onDeleteMediaFile, (deleteMediaFile) => {
      if (deleteMediaFile.mediaFile.indexType === IndexType.AOV){
        self.onDeleteMediaFile(deleteMediaFile);
      }
    });
    // set selected mediaFile
    this.eventSubscriptions[2] = this.eventService.on(Events.onMediaFileSelected, (setMediaFile) => {
      if (setMediaFile.mediaFile.indexType === IndexType.AOV){
        self.onMediaFileSelected(setMediaFile);
      }
    });
    this.initViewData(this.id);
  }

  public initViewData(id: any): void {
    this.isLoading = true;
    this.uploadService.resetFields();
    // after master-data schema is loaded, retrieve the corresponding personContainer
    this.adminService.getAovContainerById(id).subscribe({
      next: (data) => {
        this.isLoading = false;
        if (null == data) {
          this.alertService.error(
            'AOV__ADMINCLIENT__INITVIEWDATA__GETAOVCONTAINERBYID__AOVCONTAINER_DOES_NOT_EXIST',
            null,
            [this.id]
          );
          this.router.navigate(['aov']);
        } else {
          this.dataModel = AovContainer.fromResponse(data);
          this.containerService.setContainer(AovContainer.fromResponse(data));
        }
      },
      error: (error: any) => {
        this.alertService.error(error);
        this.isLoading = false;
      }
    }).add(() => {
      if (null != this.dataModel) {
        this.retrieveJsonSchema();
      }
    });
  }

  retrieveJsonSchema(): void  {
    this.isLoading = true;
    const promiseList: Promise<any>[] = [];
    const schemaName = new Aov().typeName;
    this.adminService.getSchemaViaSchemaName(schemaName).subscribe({
      next: (data: IndexSchema) => {
        this.adminService.getSheetListViaSchemaId(data.indexSchemaId).subscribe({
          next: (sheetList: IndexSchemaSheet[]) => {
            this.tabList.length = 0;
            // @ts-ignore
            sheetList.sort((a, b) => a.orderInfo - b.orderInfo);
            for (const sheet of sheetList) {
                this.tabList.push(sheet.name);
                const schemaSheet = sheet.sheetContent;
                this.schemaArray[sheet.name] = schemaSheet;
                const jsonSchema: JSONSchema7 = schemaSheet.schema as JSONSchema7;
                if (null == this.formFieldConfig[sheet.name]){
                  this.formFieldConfig[sheet.name] = [];
                }
                this.formFieldConfig[sheet.name] = [this.formlyJsonschema.toFieldConfig(jsonSchema)];
            }
            if (this.activeTab) {
              this.showTab(this.activeTab);
            }
            else {
              this.showTab({index: 0});
            }
          },
          error: (error: any) => {
            this.alertService.error(error);
            this.isLoading = false;
          }
        });
      },
      error: (error: any) => {
        this.alertService.error(error);
        this.isLoading = false;
      }
    });
  }

  showTab(tab: any): void {
    if (null == this.dataModel) {
      return;
    }
    console.log(tab);
    this.isLoading = true;
    const tabName = this.tabListMap[this.tabList[tab.index]];
    if (tab){
      this.form = new FormGroup({});
      this.options = {};
      const name = this.tabList[tab.index];
      this.fields = this.formFieldConfig[name];
      this.model = this.dataModel[tabName];
      this.adminService.isLoading = false;
      this.isMaster = this.tabList[tab.index] === 'master';
      if (this.activeTab !== tab){
        const oldTabIndexName = this.tabListMap[this.tabList[tab.index]];
        if (this.activeTab){
          this.dataModel[oldTabIndexName] = this.model;
        }
        this.activeTab = tab;
      }
    }
    this.isLoading = false;
  }

  onDeleteMediaFile(event): void {
    if (this.form.touched || this.uploadService.hasDataForUpload()){
      this.alertService.error('EXCEPTION_MESSAGE_CLIENT_ERROR__AOVDETAIL__DELETEMEDIAFILE__FORM_HAS_UNSUBMITTED_CHANGES');
      return;
    }

    const deleteMediaFile: DeleteMediaFile = event;
    const aovMediaFile = deleteMediaFile.mediaFile;
    const file = deleteMediaFile.file;

    if (!aovMediaFile.identifier){
      this.uploadService.removeMediaFileIfExist(aovMediaFile.mediaType, file);
      this.initViewData(this.id);
    }
    else {
      this.adminService.deleteAovMediaFile(this.dataModel.aovBase.identifier, aovMediaFile.identifier).subscribe({
        next: () => {
          this.uploadService.removeMediaFileIfExist(aovMediaFile.mediaType, file);
          this.initViewData(this.id);
        },
        error: (error: any) => {
          if (error === ''){

          }
          this.alertService.error(error);
          this.isLoading = false;
        }
      });
    }
  }

  onMediaFileSelected(setMediaFile: SetMediaFile): void {
    this._addMediaFile(setMediaFile.file, setMediaFile.mediaFile.mediaType);
  }

  submit(): void {
    if (this.form.touched || this.uploadService.hasDataForUpload() || this.containerService.hasChanges(this.dataModel)) {
      this.isLoading = true;
      const oldTabName = this.tabList[this.activeTab.index];
      this.dataModel[oldTabName] = this.model;

      const formData = new FormData();
      this.dataModel.identifier = this.dataModel.aovBase.identifier;
      const aovContainer = this.dataModel;
      const blob = new Blob([JSON.stringify(aovContainer)],  { type: 'application/json' });

      formData.append('aovContainer', blob);

      const fileList = this.uploadService.getUnionAllFileList();
      if (fileList && fileList.length > 0) {
        let file: File;
        for (let i = 0; i < fileList.length; i++) {
          file = fileList[i];
          if (file) {
            formData.append('files', file);
          }
          if (i === fileList.length - 1) {
            this.save(formData);
          }
        }
      }
      else {
        this.save(formData);
      }
    }
    // this.uploadService.submitFileListWithFormData(formData, this.saveData);
    // this._addFileListToFormDataAndSubmit(fileList, formData, this.saveData);
  }

  save(formData: FormData): void{
    this.adminService.saveAov(formData).subscribe({
      next: () => {
        this.formReset(this.id);
      },
      error: (errorCode: any) => {
        // const error = (typeof err.error === 'string') ? err.error : err.message;
        // this.alertService.error(error, this.formReset, this.id);
        this.formReset(this.id);
        this.alertService.error(errorCode);

      }
    });
  }
  validate(): void{
    console.log('form value changed - validate called');
    this.isValid = false;
    if (this.dataModel.aovBase) {
      this.isValid = this.utils.hasNonEmptyValue(this.dataModel.aovBase.identifier)
        && this.utils.hasNonEmptyValue(this.dataModel.aovBase.firstName)
        && this.utils.hasNonEmptyValue(this.dataModel.aovBase.lastName);
    }
  }

  private _addMediaFile(file: File, aovMediaType: MediaType): void {
    this.uploadService.addMediaFileIfNotExist(aovMediaType, file);
    this.validate();
  }

  _addToFormData(file, formData): void{
    if (file){
      formData.append('files', file);
    }
  }

  _addFileListToFormDataAndSubmit(fileList, formData, saveDataCallback): void{
    if (fileList) {
      let file: File;
      for (let i = 0; i < fileList.length; i++){
        file = fileList[i];
        this._addToFormData(file, formData);
        if (i === fileList.length - 1){
          saveDataCallback(formData);
        }
      }
    }
  }

  alertError(message: string): void {
    this.alertService.error(message);
  }

  private formReset(id: any): void {
    this.initViewData(id);
    this.uploadService.resetFields();
    this.isLoading = false;
  }
}
