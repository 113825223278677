import {IndexSchema} from '../IndexSchema';

export interface UpdateSheetContent {
  schema: Map<string, any>;
}
export interface IIndexSchemaSheet {
  sheetId: string;
  name: string;
  sheetContent: UpdateSheetContent;
  dataKey: string;
  orderInfo: bigint;
}

export class UpdateIndexSchemaSheet implements IIndexSchemaSheet {
  sheetId: string;
  schema: IndexSchema;
  name: string;
  sheetContent: UpdateSheetContent;
  dataKey: string;
  orderInfo: bigint;
  typeName: string;

  constructor(sheetId: string, name: string, schema: IndexSchema, sheetContent: UpdateSheetContent, dataKey: string, orderInfo: bigint) {
    this.sheetId = sheetId;
    this.name = name;
    this.schema = schema;
    this.sheetContent = sheetContent;
    this.dataKey = dataKey;
    this.orderInfo = orderInfo;
  }

}
