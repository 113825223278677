import {FileType, IndexType, MediaType} from './enums';

export class MediaFile {
  identifier: string;
  mediaType: MediaType;
  indexType: IndexType;
  fileType: FileType;
  originalFileName: string;
  name: string;
  description: string;
  mediaFileCredit: string;
  indexIdentifier: string;
  constructor(identifier: string,
              indexIdentifier: string,
              mediaType: MediaType,
              indexType: IndexType,
              fileType: FileType,
              originalFileName: string,
              name: string,
              description: string,
              mediaFileCredit: string) {
    this.identifier = identifier;
    this.indexIdentifier = indexIdentifier;
    this.mediaType = mediaType;
    this.indexType = indexType;
    this.fileType = fileType;
    this.originalFileName = originalFileName;
    this.name = name;
    this.description = description;
    this.mediaFileCredit = mediaFileCredit;
  }
}
export class DeleteMediaFile {
  mediaFile: MediaFile;
  file: File;
  constructor(mediaFile: MediaFile, file: File) {
    this.mediaFile = mediaFile;
    this.file = file;
  }
}
export class SetMediaFile {
  mediaFile: MediaFile;
  file: File;
  constructor(model: MediaFile, file: File) {
    this.mediaFile = new MediaFile(
      model.identifier,
      model.indexIdentifier,
      model.mediaType,
      model.indexType,
      model.fileType,
      model.originalFileName,
      model.name,
      model.description,
      model.mediaFileCredit
    );
    this.file = file;
  }
}
