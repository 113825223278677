import {Cantor} from './cantor';
import {Liturgy} from './liturgy';
import {Interview} from './interview';
import {CompositionType} from '../_shared/enums';
import {Composition} from '../_shared/composition';

export class MyNusachContainer {
    identifier: string;
    cantor: Cantor;
    liturgy: Liturgy[];
    interview: Interview[];

    constructor(identifier: string, cantor: Cantor, liturgyList: Liturgy[], interviewList: Interview[]) {
        this.identifier = identifier;
        this.cantor = cantor;
        this.liturgy = liturgyList ?? [];
        this.interview = interviewList ?? [];
    }

    static fromResponse(data: any): MyNusachContainer {
        if (null == data){
            return null;
        }

        const liturgyList = data.compositionList
            .filter((c: Composition) =>
                c.compositionType === CompositionType.LITURGY
            );
        const interviewList = data.compositionList
            .filter((c: Composition) =>
                c.compositionType === CompositionType.INTERVIEW
            );
        const container: MyNusachContainer = new MyNusachContainer(data.identifier, data.cantor, liturgyList, interviewList);
        return container;
    }
}
