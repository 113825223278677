import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertType } from '../../_model/_shared/alert.model';
import {AovContainer} from '../../_model/aov/aov-container';

export interface DialogData {
  title: string;
  message: string;
  type: AlertType;
  confirmCallback?: (param: any) => {};
  callbackParam?: object;
}

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent {

  readonly ALERT_TYPE_ERROR = AlertType.Error;
  readonly ALERT_TYPE_CONFIRM = AlertType.Confirm;
  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ){}

  okClick(): void {
    this.dialogRef.close();
    if (this.data.confirmCallback && this.data.callbackParam){
      this.data.confirmCallback(this.data.callbackParam);
    }
  }
  confirmClick(): void {

  }

  cancelClick($event): void {
    $event.preventDefault();
    this.dialogRef.close();
  }
}
