import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, throwError } from 'rxjs';

import { PersonContainer } from '../_model/person/person-container.model';
import { AovContainer } from '../_model/aov/aov-container';
import { CreateAov } from '../_model/aov/create-aov';
import { CreatePerson } from '../_model/person/create-person';
import { catchError } from 'rxjs/operators';
import { AlertService } from './alert.service';
import { IEntityType } from '../_model/IEntityType';
import { IndexSchemaSheet } from '../_model/schema/sheet/IndexSchemaSheet';
import { CreateIndexSchemaSheet } from '../_model/schema/sheet/CreateIndexSchemaSheet';
import { UpdateIndexSchemaSheet } from '../_model/schema/sheet/UpdateIndexSchemaSheet';
import { CreateSchema } from '../_model/schema/CreateSchema';
import { MyNusachContainer } from '../_model/mynusach/mynusach-container';
import { CreateMynusach } from '../_model/mynusach/create-mynusach';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  private headers: HttpHeaders = new HttpHeaders();
  private options: { headers: HttpHeaders, params?: HttpParams };
  public isLoading = true;
  constructor(private http: HttpClient,
              private authService: AuthService,
              public alertService: AlertService) {
    this.options = { headers: this.headers.set('content-type', 'application/json') };
  }

  getEntityPage(entityType: IEntityType, pageIndex: number, pageSize: number): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/api/admin/` + entityType.typeName + '/page');
  }

  createAov(createAov: CreateAov): Observable<any> {
    return this.http.put<AovContainer>(`${environment.baseUrl}/api/admin/aov`, createAov);
  }

  createMyNusach(createMyNusach: CreateMynusach): Observable<MyNusachContainer> {
    return this.http.put<MyNusachContainer>(`${environment.baseUrl}/api/admin/myn`, createMyNusach);
  }

  createPerson(createPerson: CreatePerson): Observable<any> {
    return this.http.put<AovContainer>(`${environment.baseUrl}/api/admin/person`, createPerson);
  }

  createIndexSchemaSheet(createSheet: CreateIndexSchemaSheet): Observable<IndexSchemaSheet> {
    const url = `${environment.baseUrl}/api/admin/schema/sheet`;
    return this.http.put<IndexSchemaSheet>(url, createSheet);
  }

  createIndexSchema(createSchema: CreateSchema): Observable<any> {
    return this.http.put<CreateSchema>(`${environment.baseUrl}/api/admin/schema`, createSchema);
  }

  deleteAovMediaFile(aovID: string, aovMediaFileID: string, isForceDelete: boolean = false): Observable<any> {
    return this.http.delete<any>(`${environment.baseUrl}/api/admin/aov-media-file?aovID=${aovID}&aovMediaFileID=${aovMediaFileID}&isForceDelete=${isForceDelete}`);
  }

  deleteAovViaIdentifier(identifier: string): Observable<any> {
    return this.http.delete<any>(`${environment.baseUrl}/api/admin/aov/` + identifier);
  }

  deleteMynViaIdentifier(identifier: string): Observable<any>  {
    return this.http.delete<any>(`${environment.baseUrl}/api/admin/myn/` + identifier);
  }

  deletePersonMediaFile(personIdentifier: string, mediaFileIdentifier: string): Observable<any> {
    return this.http.delete<any>(`${environment.baseUrl}/api/admin/person-media-file?personID=${personIdentifier}&mediaFileID=${mediaFileIdentifier}`);
  }

  deletePersonViaIdentifier(identifier: string): Observable<any> {
    return this.http.delete<any>(`${environment.baseUrl}/api/admin/person/` + identifier);
  }

  downloadJSON(): any {
    const httpHeaders: HttpHeaders = new HttpHeaders({
      'content-type': 'application/json',
      Accept: 'application/zip'
    });
    // tslint:disable-next-line:max-line-length
    return this.http.get<Blob>(`${environment.baseUrl}/api/admin/aov/backup`, { headers: httpHeaders, responseType: 'blob' as 'json' });
  }

  getAovDisplayContainerById(id: any): Observable<AovContainer> {
    return this.http.get<AovContainer>(`${environment.baseUrl}/api/aov/` + id);
  }

  getAovContainerById(id: any): Observable<AovContainer> {
    return this.http.get<AovContainer>(`${environment.baseUrl}/api/admin/aov/` + id);
  }

  getMYNContainerViaId(id: any): Observable<MyNusachContainer>  {
    return this.http.get<MyNusachContainer>(`${environment.baseUrl}/api/admin/myn/` + id);
  }

  getAovDisplayContainerPage(pageIndex: number, pageSize: number): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/api/aov?page=${pageIndex}&size=${pageSize}`  );
  }

  getAovPage(pageIndex: number, pageSize: number): Observable<any> {
    return this.http.get<any>(
      `${environment.baseUrl}/api/admin/aov/page?page=${pageIndex}&size=${pageSize}`
    ).pipe(catchError(this.handleError));
  }

  getMYNPage(pageIndex: number, pageSize: number): Observable<any> {
    return this.http.get<any>(
        `${environment.baseUrl}/api/admin/myn/page?page=${pageIndex}&size=${pageSize}`
    ).pipe(catchError(this.handleError));
  }

  getPersonPage(page: number, size: number): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/api/admin/person/page?page=${page}&size=${size}`  );
  }

  getAllPersonList(): Observable<PersonContainer[]> {
    return this.http.get<PersonContainer[]>(`${environment.baseUrl}/api/admin/person/all`);
  }

  getPersonById(id: any): Observable<PersonContainer> {
    return this.http.get<PersonContainer>(`${environment.baseUrl}/api/admin/person/` + id);
  }

  getPersonPortrait(personIdentifier, mediaIdentifier): Observable<any>{
    // @ts-ignore
    return this.http.get<any>(
      `${environment.baseUrl}/api/admin/person/${personIdentifier}/${mediaIdentifier}`, // @ts-ignore
      { observe: 'events', responseType: 'blob' }
    );
  }

  getSchemaViaSchemaName(schemaName: string): Observable<any> {
    return this.http.get(`${environment.baseUrl}/api/admin/schema?name=${schemaName}`);
  }
  getSheetListViaSchemaId(schemaId: any): Observable<any> {
    return this.http.get(`${environment.baseUrl}/api/admin/schema/sheet?schemaId=${schemaId}`);
  }

  saveAov(formData: FormData): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}/api/admin/aov`, formData);
  }

  saveMyNusach(formData: FormData): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}/api/admin/myn`, formData);
  }

  savePerson(formData: FormData): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}/api/admin/person`, formData);
  }

  saveSchemaSheet(sheet: UpdateIndexSchemaSheet): Observable<any>  {
    return this.http.post<any>(`${environment.baseUrl}/api/admin/schema/sheet`, sheet);
  }

  get getIsLoading(): boolean {
    return this.isLoading;
  }

  set setIsLoading(value: boolean) {
    this.isLoading = value;
  }


  getJsonSchema(schemaPath: string): Observable<any> {
    return this.http.get<any>(schemaPath);
  }

  handleError = (error: any) => {
    let errorMessage: string;
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    }
    else {
      // Get server-side error
      if (error.status) {
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }
      else if (typeof error === 'string' && error.startsWith('<') && error.includes('HTTP Status 401 – Unauthorized')) {
        const status = 401;
        const message = 'HTTP Status 401 – Unauthorized';
        errorMessage = `Error Code: ${status}\nMessage: ${message}`;
        // this.authService.logout();
      }
      else {
        errorMessage = error;
      }

    }
    this.alertService.error(errorMessage);
    // window.alert(errorMessage);
    return throwError(errorMessage);
  }
}
