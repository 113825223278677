import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(){}

  hasNonEmptyValue(value: string): boolean{
    return !this.isNullOrEmpty(value);
  }

  isNullOrEmpty(value: string): boolean{
    return null === value || undefined === value || "" === value || "" === value.replace(/\s/g, "");
  }
}
