<div class="form-container">
  <div class="loading-indicator" *ngIf="isLoading">
    <mat-spinner style="position:relative;top:calc(50% - 100px);margin: 0 auto;"></mat-spinner>
  </div>
  <div style="height:48px;background-color: #999">
    <div style="width: 100%;height:100%;padding:1rem 0rem;margin:0 auto;background-color: #333333;">
      <h2 *ngIf="null != schema" style="margin:0 auto;padding: 0rem 3rem;background-color: #333333;color: #fff;text-transform: uppercase;text-decoration: underline">{{schema.name}}</h2>
    </div>
    <button mat-fab
            color="primary"
            type="submit"
            class=""
            matTooltip="Create New"
            (click)="openDialog()"
            style="position:absolute;right: 16px;top:83px; z-index: 99">
      <mat-icon>add</mat-icon>
    </button>
  </div>

  <mat-tab-group class="mat-primary" color="primary" mat-stretch-tabs="false" mat-align-tabs="center" (selectedTabChange)="showTab($event)" style="background-color: #ddd">
    <mat-tab *ngFor="let tab of tabList">
      <ng-template mat-tab-label style="text-transform: capitalize">
        <span class="tab-label">{{tab}}</span>
      </ng-template>
      <ng-template matTabContent>
        <form [formGroup]="form" (ngSubmit)="submit()" *ngIf="fields">
          <formly-form class="form-content" [model]="model" [fields]="fields" [options]="options" [form]="form"></formly-form>
          <br>
          <button mat-fab color="primary" type="submit" class="save-button">
            <mat-icon>save</mat-icon>
          </button>
        </form>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
