<div class="form-container">
  <div class="loading-indicator" *ngIf="isLoading">
    <mat-spinner style="position:relative;top:calc(50% - 100px);margin: 0 auto;"></mat-spinner>
  </div>
  <mat-tab-group color="primary" mat-stretch-tabs="false" mat-align-tabs="center" (selectedTabChange)="showTab($event)">
    <mat-tab *ngFor="let tab of tabList" (click)="this.isLoading = true;">
      <ng-template mat-tab-label style="text-transform: capitalize">
        <span class="tab-label">{{tab}}</span>
      </ng-template>
      <ng-template matTabContent>
      <form [formGroup]="form" (ngSubmit)="submit()" *ngIf="fields">
        <br>
        <button mat-fab color="primary" type="submit" class="save-button">
          <mat-icon>save</mat-icon>
        </button>
        <formly-form class="form-content" [model]="model" [fields]="fields" [options]="options" [form]="form"></formly-form>
      </form>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>

