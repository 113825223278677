import {Component, Inject} from '@angular/core';
import {Router} from '@angular/router';
import {AdminService} from '../../../_service/admin.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CreateIndexSchemaSheet} from '../../../_model/schema/sheet/CreateIndexSchemaSheet';
import {IndexSchemaSheet} from '../../../_model/schema/sheet/IndexSchemaSheet';
import {CreateSchema} from '../../../_model/schema/CreateSchema';

export interface CreateSchemaOrSheetData {
  dialogType: string;
  schemaId?: number;
  name: string;
  dataKey?: string;
}

@Component({
  selector: 'app-schema-create',
  templateUrl: './schema-create.component.html',
  styleUrls: ['./schema-create.component.scss']
})
export class SchemaCreateComponent {
  private returnUrl = '/schema';
  private dialogType;
  hasErrors = false;
  constructor(
    private router: Router,
    private adminService: AdminService,
    public dialogRef: MatDialogRef<SchemaCreateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CreateSchemaOrSheetData,
  ) {}

  save(data: any): void {
    this.adminService.isLoading = true;
    if ('SHEET' === data.dialogType) {
      const createSheet =  new CreateIndexSchemaSheet(data.schemaId, data.name, data.dataKey);
      this.adminService.createIndexSchemaSheet(createSheet).subscribe({
        next: (result: IndexSchemaSheet) => {
          this.router.navigateByUrl(this.returnUrl + '/' + result.schema.indexSchemaId  + '/edit');
          this.dialogRef.close();
          console.log(result);
        },
        error: (err) => {
          console.log(err);
          this.adminService.isLoading = false;
          this.hasErrors = true;
          this.dialogRef.close();
        }
      });
    }

    if ('SCHEMA' === data.dialogType) {
      const createSchema = new CreateSchema(data.name);
      this.adminService.createIndexSchema(createSchema).subscribe({
        next: (result) => {
          this.router.navigateByUrl(this.returnUrl + '/' + result.schemaId  + '/edit');
          this.dialogRef.close();
          console.log(result);
        },
        error: (err) => {
          console.log(err);
          this.adminService.isLoading = false;
          this.hasErrors = true;
          this.dialogRef.close();
        }
      });
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
