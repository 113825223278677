import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { PersonContainer } from '../../../_model/person/person-container.model';
import { AdminService } from '../../../_service/admin.service';
import { AlertService } from '../../../_service/alert.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent implements OnInit {
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  pageEvent: PageEvent;
  isLoading = true;
  pageNumber: number;
  pageSize: number;
  page: any;
  totalSize: number;
  dataSource = new MatTableDataSource<PersonContainer>();
  private matTableDataModel: PersonContainer[];
  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['identifier', 'firstname', 'lastname', 'action'];

  private returnUrl = '/people';
  constructor(private router: Router,
              private adminService: AdminService,
              private alertService: AlertService) {
    this.matTableDataModel = [];
    this.pageEvent = new PageEvent();
    this.pageEvent.pageIndex = 0;
    this.pageEvent.pageSize = 200;
    this.pageEvent.length = 0;
  }

  ngOnInit(): void {
    this.loadData(this.pageEvent);
  }

  deletePerson(row: any): void {
    this.adminService.isLoading = true;
    this.adminService.deletePersonViaIdentifier(row.identifier).subscribe({
      next: (response) => {
        this.adminService.isLoading = false;
        this.ngOnInit();
      },
      error: err => {
        this.adminService.isLoading = false;
        this.alertService.error(err);
      }
    });
  }

  loadData(pageEvent: PageEvent): void {
    const self = this;
    this.adminService.getPersonPage(pageEvent.pageIndex, pageEvent.pageSize).subscribe({
      next: (page) => {
        self.totalSize = page.totalElements;
        self.pageSize = page.size;
        self.pageNumber = page.number;
        self.dataSource.data = page.content;
        // self.paginator.getNumberOfPages()
        // self.dataSource.paginator = this.paginator;
        self.dataSource.sort = this.sort;
        self.isLoading = false;
      },
      error: (error: any) => {
        self.alertService.error(error);
        self.isLoading = false;
      }
    });
  }

  identifierSort(a: PersonContainer, b: PersonContainer, isAsc: boolean) {
    const aArr = a.person.personBase.identifier.replace('SOSY_', '').split('_');
    const bArr = b.person.personBase.identifier.replace('SOSY_', '').split('_');
    const aNum = parseInt(aArr[1], 10);
    const bNum = parseInt(bArr[1], 10);
    if (isAsc) {
      return (
        aArr[0] < bArr[0] ? -1 :
        aArr[0] > bArr[0] ? 1 :
        aNum < bNum ? -1 :
        aNum > bNum ? 1 :
        0
      );
    }
    else{
      return (
        aArr[0] < bArr[0] ? 1 :
        aArr[0] > bArr[0] ? -1 :
        aNum < bNum ? 1 :
        aNum > bNum ? -1 :
        0
      );
    }
  }

  sortMyData(sort: Sort): void {
    const isAsc = sort.direction == 'asc';
    const collator = new Intl.Collator(undefined, {
      numeric: true,
      sensitivity: 'base'
    });
    switch (sort.active){
        case 'identifier':
          this.dataSource.data.sort((a, b) => this.identifierSort(a, b, isAsc));
          break;
        case 'firstname':
          this.dataSource.data.sort((a: PersonContainer, b: PersonContainer) => {
            return isAsc
              ? collator.compare(a.person.personBase.firstName, b.person.personBase.firstName)
              : collator.compare(b.person.personBase.firstName, a.person.personBase.firstName);
          });
          break;
        case 'lastname':
          this.dataSource.data.sort((a: PersonContainer, b: PersonContainer) => {
            return isAsc ? a.person.personBase.lastName.localeCompare(b.person.personBase.lastName)
              : b.person.personBase.lastName.localeCompare(a.person.personBase.lastName);
          });
          break;
      }
  }

  openPersonDetails(row) {
    // console.log(this.returnUrl + '/' + row.id);
    this.router.navigateByUrl(this.returnUrl + '/' + row.identifier + '/edit');
  }

  getServerData($event: PageEvent) {
    // this.pageEvent = $event;
    this.loadData($event);

    this.pageEvent = new PageEvent();
    this.pageEvent.pageIndex = $event.pageIndex;
    this.pageEvent.previousPageIndex = $event.previousPageIndex;
    this.pageEvent.pageSize = $event.pageSize;
    this.pageEvent.length = $event.length;
    return null;
  }
}
