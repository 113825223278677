import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { SetMediaFile, DeleteMediaFile } from '../../_model/_shared/media-file';
import { EmitEvent, EventBusService, Events } from '../../_service/event-bus.service';
import { FormControl } from '@angular/forms';
import { ContainerService } from '../../_service/container.service';
import { AovContainer } from '../../_model/aov/aov-container';
import { MyNusachContainer } from '../../_model/mynusach/mynusach-container';
import {FileType, MediaType} from "../../_model/_shared/enums";

@Component({
  selector: 'formly-field-audio-mp3',
  styleUrls: ['./audio-type.scss'],
  templateUrl: './audio-mp3.component.html',
  // template: ``,
})
export class FormlyAudioFileField extends FieldType implements OnInit
{

  constructor(public eventService: EventBusService, public containerService: ContainerService)
  {
    super();
  }

  private file: File;
  filename: FormControl;
  @Output() onDeleteMediaFile = new EventEmitter<DeleteMediaFile>();
  @Output() onMediaFileSelected = new EventEmitter<SetMediaFile>();


  ngOnInit(): void { }

  alertError(message: string): void {
    this.eventService.emit(new EmitEvent(Events.onAlertError, message));
  }

  delete(mediaFile): void {
    const deleteMediaFile = new DeleteMediaFile(mediaFile, this.file);
    this.eventService.emit(new EmitEvent(Events.onDeleteMediaFile, deleteMediaFile));
  }

  onFileNameChange(event): void {
    if (!this.model.audioFile.originalFileName){
      this.model.audioFile.originalFileName = this.model.audioFile.name;
    }
    this.model.audioFile.name = event.target.value;
  }

  setAudioFile(event): void{
    const file: File = event.target.files[0];
    const formAction = event.target.formAction;
    const allowedExtensions = /(\.mp3|\.m4a|\.mp4)$/i;
    if (!allowedExtensions.exec(file.name)) {
      this.alertError('Please upload file having extensions .mp3/.m4a only.');
    }
    else {
      const container = this.containerService.getContainer();
      let masterData = null;
      if (container.identifier.startsWith('AOV')){
        masterData = (container as AovContainer).aovBase;
      }
      if (container.identifier.startsWith('MYN')){
        masterData = (container as MyNusachContainer).cantor;
      }
      console.log(this.model);
      this.model.audioFile = {};
      this.model.audioFile.indexType = this.eventService.getIndexTypeViaFormAction(formAction);
      this.model.audioFile.indexIdentifier = this.eventService.getIdentifierViaFormAction(formAction);
      this.model.audioFile.identifier;
      this.model.audioFile.mediaFileCredit;
      this.model.audioFile.description;
      this.model.audioFile.fileType = FileType.MP3;
      this.model.audioFile.mediaType = MediaType.AUDIO;
      this.model.audioFile.originalFileName = file.name;
      this.model.audioFile.name = file.name;
      this.model.audioFile.name = masterData.lastName
          + ',' + masterData.firstName
          + ' - ' + this.model.songTitle
          + ' - ' + this.model.audioFile.mediaType;

      const setMediaFile = new SetMediaFile(this.model.audioFile, file);
      this.eventService.emit(new EmitEvent(Events.onMediaFileSelected, setMediaFile));
      this.file = file;
    }
  }
}
