import {Component, ViewContainerRef} from '@angular/core';
import {FieldArrayType, FieldType, FieldWrapper} from '@ngx-formly/core';

@Component({
  selector: 'formly-array-type',
  styleUrls: ['./simple-array.scss'],
  templateUrl: './simple-array.html',
  //  template: ``,
})
export class ArraySimpleTypeComponent extends FieldArrayType implements FieldWrapper, FieldType {
  _staticContent: ViewContainerRef;
  fieldComponent: ViewContainerRef;
  add(i?: number, initialModel?: any, markAsDirty?: { markAsDirty: boolean; }): void {
    super.add();
  }

}
