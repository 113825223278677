import {Component, EventEmitter, Output} from '@angular/core';
import {FieldType} from '@ngx-formly/core';
import {
  DeleteMediaFile,
  SetMediaFile
} from '../../_model/_shared/media-file';
import {EmitEvent, EventBusService, Events} from '../../_service/event-bus.service';
import {FileType, MediaType} from "../../_model/_shared/enums";

@Component({
  selector: 'formly-field-image',
  styleUrls: ['./img-type.scss'],
  template: `
    <input class="file-input"
           type="file"
           accept=".jpg,.png"
           (change)="setImg($event)"
           #imageFileInput>
    <button *ngIf="!model.name" mat-flat-button
            type="button"
            color="primary"
            class="upload-btn"
            (click)="imageFileInput.click()">Choose File
      <mat-icon>attach_file</mat-icon>
    </button>
    <button *ngIf="model && model.identifier" mat-flat-button
            type="button"
            color="warn"
            class="delete-btn"
            (click)="delete(model)">Delete File
      <mat-icon>delete</mat-icon>
    </button>
    <br>
    <div class="file-upload">{{ model.name || "No image selected yet." }}</div>
    <div class="file-upload" *ngIf="!model">{{ "No file selected yet." }}</div>
    <mat-divider></mat-divider><br>
  `,
})
export class FormlyImgFileField extends FieldType {
  private oldFile: File;
  constructor(public eventService: EventBusService) {
    super();
  }

  @Output() onDeleteMediaFile = new EventEmitter<DeleteMediaFile>();

  @Output() onMediaFileSelected = new EventEmitter<SetMediaFile>();

  alertError(message: string): void {
    this.eventService.emit(new EmitEvent(Events.onAlertError, message));
  }

  delete(mediaFile): void {
    const deleteMediaFile = new DeleteMediaFile(mediaFile, this.oldFile);
    this.eventService.emit(new EmitEvent(Events.onDeleteMediaFile, deleteMediaFile));
  }

  setImg(event): void {
    const file: File = event.target.files[0];
    const formAction = event.target.formAction;
    const allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
    if (!allowedExtensions.exec(file.name)){
      this.alertError('Please upload file having extensions .jpeg/.jpg/.png only.');
    }
    else {
      this.model.identifier;
      this.model.description;
      this.model.mediaFileCredit;
      this.model.indexIdentifier = this.eventService.getIdentifierViaFormAction(formAction);
      this.model.indexType = this.eventService.getIndexTypeViaFormAction(formAction);
      this.model.fileType = FileType.JPG;
      this.model.mediaType = MediaType.IMG;
      this.model.originalFileName = file.name;
      this.model.name = file.name;

      const setMediaFile = new SetMediaFile(this.model, file);
      this.eventService.emit(new EmitEvent(Events.onMediaFileSelected, setMediaFile));
      this.oldFile = file;
    }
  }
}
