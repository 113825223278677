import { Injectable } from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpEventType} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from '../_service/auth.service';
import { AlertService } from '../_service/alert.service';
import { environment } from '../../environments/environment';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private alertService: AlertService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      const jwtContainer = this.authService.getToken;
      const isLoggedIn = jwtContainer && jwtContainer.access_token;
      const isApiUrl = request.url.startsWith(environment.baseUrl);
      if (isLoggedIn && isApiUrl) {
        request = request.clone({ setHeaders: { Authorization: `Bearer ${jwtContainer.access_token }` }});
      }
      return next.handle(request).pipe(
        catchError
        ((errObj: any, caught: Observable<HttpEvent<any>>
          ) => {
            let errorMessage = null;
            if ([401, 403, 0].includes(errObj.status)) {
               // auto logout if 401 or 403 response returned from api
                if (typeof errObj.error === 'string' && errObj.error.startsWith('<') && errObj.error.includes('HTTP Status 401 – Unauthorized')) {
                    const status = 401;
                    const message = 'HTTP Status 401 – Unauthorized';
                    errorMessage = `Error Code: ${status}\nMessage: ${message}`;
                }
                this.authService.logout();
            }
            else {
                if (typeof errObj === 'string' && errObj.startsWith('<') && errObj.includes('HTTP Status 401 – Unauthorized')) {
                    const status = 401;
                    const message = 'HTTP Status 401 – Unauthorized';
                    errorMessage = `Error Code: ${status}\nMessage: ${message}`;
                    this.authService.logout();
                }
            }
            errorMessage = null != errorMessage ?  errorMessage : (typeof errObj.error === 'string') ? errObj.error : errObj.message;
            return throwError(errorMessage);
        })
    );
  }

}


