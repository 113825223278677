import {Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { AlertModel, AlertType } from '../_model/_shared/alert.model';
import { AlertService } from '../_service/alert.service';
import { DialogComponent } from '../components/dialog/dialog.component';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';

export interface DialogData {
  title: string;
  message: string;
}

@Component({  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']})
export class AlertComponent implements OnInit, OnDestroy {
  @Input() id = 'default-alert';
  @Input() fade = true;

  title: string;
  message: string;
  type: AlertType;
  callback: (row) => void;
  callbackParam: object;


  alerts: AlertModel[] = [];
  alertSubscription: Subscription | undefined;
  routeSubscription: Subscription | undefined;

  constructor(private router: Router,
              private alertService: AlertService,
              public dialog: MatDialog) { }

  ngOnInit(): void {
    // subscribe to new alert notifications
    this.alertSubscription = this.alertService.onAlert(this.id)
      .subscribe(alert => {
        // clear alerts when an empty alert is received
        if (!alert.message && !alert.title) {
          // filter out alerts without 'keepAfterRouteChange' flag
          this.alerts = this.alerts.filter(x => x.keepAfterRouteChange);
          // remove 'keepAfterRouteChange' flag on the rest
          this.alerts.forEach(x => delete x.keepAfterRouteChange);
          return;
        }
        this.title = alert.title;
        this.message = alert.message;
        this.type = alert.type;
        this.callback = alert.confirmCallback;
        this.callbackParam = alert.callbackParam;
        this.openDialog();

        // add alert to array
        this.alerts.push(alert);

        // auto close alert if required
        if (alert.autoClose) {
          setTimeout(() => this.removeAlert(alert), 3000);
        }
      });

    // clear alerts on location change
    this.routeSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.alertService.clear(this.id);
      }
    });
  }

  ngOnDestroy(): void {
    // unsubscribe to avoid memory leaks
    if (this.alertSubscription){
      this.alertSubscription.unsubscribe();
    }
    if (this.routeSubscription){
      this.routeSubscription.unsubscribe();
    }
  }

  removeAlert(alert: AlertModel): void {
    // check if already removed to prevent error on auto close
    if (!this.alerts.includes(alert)) { return; }

    if (this.fade) {
      // fade out alert
      if (this.alerts){
        // @ts-ignore
        this.alerts.find(x => x === alert).fade = true;

        // remove alert after faded out
        setTimeout(() => {
          this.alerts = this.alerts.filter(x => x !== alert);
        }, 250);
      }

    } else {
      // remove alert
      this.alerts = this.alerts.filter(x => x !== alert);
    }
  }

  cssClass(alert: AlertModel): string {
    const classes = ['alert', 'alert-dismissable'];
    const alertTypeClass = {
      [AlertType.Success]: 'alert-success',
      [AlertType.Error]: 'alert-danger',
      [AlertType.Info]: 'alert-info',
      [AlertType.Warning]: 'alert-warning'
    };

    classes.push(alertTypeClass[alert.type]);

    if (alert.fade) {
      classes.push('fade');
    }

    return classes.join(' ');
  }

  closeDialog(): void {

  }

  openDialog(): void {
    // this.dataTable.ngOnInit();
    // todo: maybe implement reload datatable's content before creation & insertion of new item
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = true;
    dialogConfig.panelClass = ['dialog-panel'];

    // dialogConfig.direction = 'rtl';
    // dialogConfig.width = '800px';
    dialogConfig.data = {
      title: this.title,
      message: this.message,
      type: this.type,
      confirmCallback: this.callback,
      callbackParam: this.callbackParam
    };

    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.message = result;
    });
  }
}
