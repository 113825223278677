import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort, Sort} from '@angular/material/sort';
import {AovContainer} from '../../../_model/aov/aov-container';
import {Router} from '@angular/router';
import {AdminService} from '../../../_service/admin.service';
import {AlertService} from '../../../_service/alert.service';
import {Aov} from '../../../_model/EntityType';


@Component({
  selector: 'app-aov-data-table',
  templateUrl: './aov-data-table.component.html',
  styleUrls: ['./aov-data-table.component.scss']
})
export class AovDataTableComponent implements OnInit {
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  pageEvent: PageEvent;
  isLoading = true;
  pageNumber: number;
  pageSize: number;
  page: any;
  totalSize: number;
  dataSource = new MatTableDataSource<AovContainer>();
  // private matTableDataModel: AovContainer[];
  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['identifier', 'firstname', 'lastname', 'action'];

  private returnUrl = '/aov';

  aovEntityType: Aov = new Aov();

  constructor(private router: Router,
              private adminService: AdminService,
              private alertService: AlertService)
  {
    // this.matTableDataModel = [];
    this.pageEvent = new PageEvent();
    this.pageEvent.pageIndex = 0;
    this.pageEvent.pageSize = 200;
    this.pageEvent.length = 0;
  }

  ngOnInit(): void {
    this.loadData(this.pageEvent);
  }

  loadData(pageEvent: PageEvent): void  {
    const self = this;
    this.adminService.getAovPage(pageEvent.pageIndex, pageEvent.pageSize).subscribe({
    // this.adminService.getEntityPage(this.aovEntityType, pageEvent.pageIndex, pageEvent.pageSize).subscribe({
      next: (page: any): void => {
        self.totalSize = page.totalElements;
        self.pageSize = page.size;
        self.pageNumber = page.number;
        self.dataSource.data = page.content;
        self.dataSource.sort = this.sort;
        self.isLoading = false;
      }
    });
  }

  getServerData($event: PageEvent): PageEvent {
    this.loadData($event);
    return null;
  }

  identifierSort(a: AovContainer, b: AovContainer, isAsc: boolean): number {
    const aArr = a.aovBase.identifier.replace('AOV_', '').split('_');
    const bArr = b.aovBase.identifier.replace('AOV_', '').split('_');
    const aNum = parseInt(aArr[1], 10);
    const bNum = parseInt(bArr[1], 10);
    if (isAsc) {
      return (
        aArr[0] < bArr[0] ? -1 :
          aArr[0] > bArr[0] ? 1 :
            aNum < bNum ? -1 :
              aNum > bNum ? 1 :
                0
      );
    }
    else{
      return (
        aArr[0] < bArr[0] ? 1 :
          aArr[0] > bArr[0] ? -1 :
            aNum < bNum ? 1 :
              aNum > bNum ? -1 :
                0
      );
    }
  }

  sortMyData(sort: Sort): void {
    const isAsc = sort.direction == 'asc';
    const collator = new Intl.Collator(undefined, {
      numeric: true,
      sensitivity: 'base'
    });
    switch (sort.active){
      case 'identifier':
        this.dataSource.data.sort((a, b) => this.identifierSort(a, b, isAsc));
        break;
      case 'firstname':
        this.dataSource.data.sort((a: AovContainer, b: AovContainer) => {
          return isAsc ? collator.compare(a.aovBase.firstName, b.aovBase.firstName)
            : collator.compare(b.aovBase.firstName, a.aovBase.firstName);
        });
        break;
      case 'lastname':
        this.dataSource.data.sort((a: AovContainer, b: AovContainer) => {
          return isAsc ? a.aovBase.lastName.localeCompare(b.aovBase.lastName) : b.aovBase.lastName.localeCompare(a.aovBase.lastName);
        });
        break;
    }
  }

  openAovDetails(row): void {
    this.router.navigateByUrl(this.returnUrl + '/' + row.identifier + '/edit');
  }


  deleteAov(row): void {
    const call = this.deleteAovViaRow;
    this.alertService.confirm('Soll der Eintrag wirklich gelöscht werden?', call, row);
    this.adminService.isLoading = true;
  }

  deleteAovViaRow = (row) => {
    this.adminService.deleteAovViaIdentifier(row.identifier).subscribe({
      next: (response) => {
        this.adminService.isLoading = false;
        this.ngOnInit();
      }
    });
  }
}
