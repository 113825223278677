import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatTabGroup} from '@angular/material/tabs';
import {FormGroup} from '@angular/forms';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {AdminService} from '../../../_service/admin.service';
import {AlertService} from '../../../_service/alert.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {FormlyJsonschema} from '@ngx-formly/core/json-schema';
import {UtilsService} from '../../../_service/utils.service';
import {HttpClient} from '@angular/common/http';
import {IndexSchemaContainer} from '../../../_model/schema/IndexSchemaContainer';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {SchemaCreateComponent} from '../schema-create/schema-create.component';
import {IndexSchema} from '../../../_model/schema/IndexSchema';
import {IndexSchemaSheet} from '../../../_model/schema/sheet/IndexSchemaSheet';
import {UpdateIndexSchemaSheet} from '../../../_model/schema/sheet/UpdateIndexSchemaSheet';
import {Subscription} from "rxjs";

@Component({
  selector: 'app-schema-detail',
  templateUrl: './schema-detail.component.html',
  styleUrls: ['./schema-detail.component.scss']
})
export class SchemaDetailComponent implements  OnInit, OnDestroy {
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;

  private schemaId;
  private sheetId;
  private id: any;
  private dataModel: Map<string, any>;

  schemaContainer: IndexSchemaContainer;
  schema: IndexSchema;
  isMaster = true;
  isLoading: boolean;
  isUpdate: boolean;

  form: FormGroup;
  model: any;
  tabList: string[] = [];

  adminService: AdminService;
  alertService: AlertService;
  activeTab: any;
  type: string;

  options: {};
  fields: FormlyFieldConfig[] = [
    {
      key: 'schema',
      type: 'textarea',
      props: {
        label: 'Schema',
        placeholder: 'add schema',
        required: true,
        autosize: true
      }
    }
  ];
  private navigationSubscription: Subscription;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private formlyJsonschema: FormlyJsonschema,
    private utils: UtilsService,
    private http: HttpClient,
    adminService: AdminService,
    alertService: AlertService
  ) {
    this.adminService = adminService;
    this.alertService = alertService;
    this.adminService.isLoading = true;

    route.url.subscribe(() => {
      if (null != route.snapshot.params.id) {
        this.isUpdate = true;
        this.id = route.snapshot.params.id;
      }
    });
  }


  ngOnInit(): void {
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.initViewData(this.id);
      }
    });
    this.initViewData(this.id);
  }

  ngOnDestroy(): void {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  private formReset(id: any): void {
    this.initViewData(id);
    this.isLoading = false;
  }

  public initViewData(id: any): void {
    this.isLoading = true;
    this.adminService.getSheetListViaSchemaId(id).subscribe({
      next: (data) => {
        this.isLoading = false;
        if (null == data) {
          const errCode =
            'SCHEMA__ADMINCLIENT__INITVIEWDATA__GETINDEXSCHEMACONTAINERBYID__INDEXSCHEMACONTAINER_DOES_NOT_EXIST';
          this.alertService.error(
            errCode, null, [this.id]
          );
          this.router.navigate(['schema']);
        }
        else if (0 === data.length){

        }
        else {
          this.schemaContainer = IndexSchemaContainer.fromResponse(data);
          const schemaSheetMap = this.schemaContainer.sheetMap;
          this.tabList = Array.from(schemaSheetMap.keys());
          console.log(this.tabList);
          this.dataModel = schemaSheetMap;
          this.schema = this.schemaContainer.indexSchema;
          if (this.activeTab) {
            this.showTab(this.activeTab);
          }
          else {
            this.showTab({index: 0});
          }
        }
      },
      error: (error: any) => {
        this.alertService.error(error);
        this.isLoading = false;
      }
    });
  }

  showTab(tab: any): void {
    if (null == this.dataModel) {
      return;
    }

    console.log(tab);
    this.isLoading = true;
    if (tab){
      this.isMaster = this.tabList[tab.index] === 'master';
      this.form = new FormGroup({});
      const key = this.tabList[tab.index];
      const loadedSheet: IndexSchemaSheet = this.dataModel.get(key);
      if (!this.model){
        this.model = { schema: null };
      }
      if (null != loadedSheet.sheetContent){
        this.model.schema = JSON.stringify(loadedSheet.sheetContent, null, 2);
      }else{
        this.model = { schema: null };
      }
      if (this.activeTab !== tab){
        const oldTabIndex = this.tabList[tab.index];
        if (this.activeTab){
          this.dataModel[oldTabIndex] = this.model;
        }
        this.activeTab = tab;
      }
    }
    this.isLoading = false;
  }

  submit(): void {
    this.isLoading = true;
    const name = this.tabList[this.activeTab.index];
    const oldSheet = this.schemaContainer.sheetMap.get(name);
    const sheet: UpdateIndexSchemaSheet =
      new UpdateIndexSchemaSheet(oldSheet.sheetId, name, this.schema, JSON.parse(this.model.schema), oldSheet.dataKey, oldSheet.orderInfo);
    this.adminService.saveSchemaSheet(sheet).subscribe({
      next: () => {
        this.formReset(this.id);
      },
      error: (errorCode: any) => {
        this.formReset(this.id);
        this.alertService.error(errorCode);
      }
    });
  }

  openDialog(): void {
    // this.dataTable.ngOnInit();
    // todo: maybe implement reload datatable's content before creation & insertion of new item
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = true;
    dialogConfig.panelClass = ['aov-create-dialog-panel'];

    dialogConfig.direction = 'rtl';
    // dialogConfig.width = '800px';
    dialogConfig.data = { dialogType: 'SHEET', schemaId: this.id, name: ''};

    const dialogRef = this.dialog.open(SchemaCreateComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

}
