import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {AovCreateDialogComponent} from '../aov-create-dialog/aov-create.component';
import {AovDataTableComponent} from '../aov-data-table/aov-data-table.component';
import {UploadService} from '../../file-upload.service';

@Component({
  selector: 'app-aov-master',
  templateUrl: './aov-master.component.html',
  styleUrls: ['./aov-master.component.scss']
})
export class AovMasterComponent implements OnInit {
  @ViewChild(AovDataTableComponent) dataTable: AovDataTableComponent;
  identifier: string;
  firstName: string;
  lastName: string;

  private returnUrl = '/aov';
  constructor(private router: Router,
              private uploadService: UploadService,
              public dialog: MatDialog) { }

  ngOnInit(): void {
    this.uploadService.resetFields();
  }

  openAovEditDetails(identifier: string): void {
    const path = identifier ? '/' + identifier + '/edit' : '/new';
    this.router.navigateByUrl(this.returnUrl + path);
  }

  openDialog(): void {
    // this.dataTable.ngOnInit();
    // todo: maybe implement reload datatable's content before creation & insertion of new item
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = true;
    dialogConfig.panelClass = ['aov-create-dialog-panel'];

    dialogConfig.direction = 'rtl';
    // dialogConfig.width = '800px';
    dialogConfig.data = {identifier: this.identifier, firstName: this.firstName, lastName: this.lastName};

    const dialogRef = this.dialog.open(AovCreateDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.lastName = result;
    });
  }
}
