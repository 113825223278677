import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort, Sort} from '@angular/material/sort';

import {Router} from '@angular/router';
import {AdminService} from '../../../_service/admin.service';
import {AlertService} from '../../../_service/alert.service';
import {IndexSchema} from '../../../_model/schema/IndexSchema';
import {Schema} from '../../../_model/EntityType';


@Component({
  selector: 'app-schema-data-table',
  templateUrl: './schema-data-table.component.html',
  styleUrls: ['./schema-data-table.component.scss']
})
export class SchemaDataTableComponent implements OnInit {
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  pageEvent: PageEvent;
  isLoading = true;
  pageNumber: number;
  pageSize: number;
  page: any;
  totalSize: number;
  dataSource = new MatTableDataSource<IndexSchema>();
  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['indexSchemaId', 'name', 'a', 'action'];

  private returnUrl = '/schema';

  schemaEntityType: Schema = new Schema();

  constructor(private router: Router,
              private adminService: AdminService,
              private alertService: AlertService)
  {
    // this.matTableDataModel = [];
    this.pageEvent = new PageEvent();
    this.pageEvent.pageIndex = 0;
    this.pageEvent.pageSize = 200;
    this.pageEvent.length = 0;
  }

  ngOnInit(): void {
    this.loadData(this.pageEvent);
  }

  loadData(pageEvent: PageEvent): void {
    const self = this;
    // this.adminService.getAovPage(pageEvent.pageIndex, pageEvent.pageSize).subscribe({
    this.adminService.getEntityPage(this.schemaEntityType, pageEvent.pageIndex, pageEvent.pageSize).subscribe({
      next: (page: any): void => {
        self.totalSize = page.totalElements;
        self.pageSize = page.size;
        self.pageNumber = page.number;
        self.dataSource.data = page.content;
        self.dataSource.sort = this.sort;
        self.isLoading = false;
      }
    });
  }

  getServerData($event: PageEvent): PageEvent {
    this.loadData($event);
    return null;
  }

  sortMyData(sort: Sort): void {
    const isAsc = sort.direction == 'asc';
    const collator = new Intl.Collator(undefined, {
      numeric: true,
      sensitivity: 'base'
    });
    switch (sort.active){
      case 'indexSchemaId':
        this.dataSource.data.sort((a, b) => {
        return isAsc ? collator.compare(a.indexSchemaId, b.indexSchemaId)
          : collator.compare(b.indexSchemaId, a.indexSchemaId);
      });
        break;
      case 'name':
        this.dataSource.data.sort((a: IndexSchema, b: IndexSchema) => {
          return isAsc ? collator.compare(a.name, b.name)
            : collator.compare(b.name, a.name);
        });
        break;
    }
  }

  openDetails(row): void {
    this.router.navigateByUrl(this.returnUrl + '/' + row.indexSchemaId + '/edit');
  }


  deleteSchema(row): void {
    const call = this.deleteViaRow;
    this.alertService.confirm('Soll der Eintrag wirklich gelöscht werden?', call, row);
    this.adminService.isLoading = true;
  }

  deleteViaRow = (row) => {
    // this.adminService.deleteSchemaViaIdentifier(row.identifier).subscribe({
    //   next: (response) => {
    //     this.adminService.isLoading = false;
    //     this.ngOnInit();
    //   }
    // });
  }

}
