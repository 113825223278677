import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {MyNusachCreateDialogComponent} from '../myn-create-dialog/myn-create.component';
import {MynDataTableComponent} from '../myn-data-table/myn-data-table.component';
import {UploadService} from '../../file-upload.service';

@Component({
  selector: 'app-myn-master',
  templateUrl: './myn-master.component.html',
  styleUrls: ['./myn-master.component.scss']
})
export class MynMasterComponent implements OnInit {
  @ViewChild(MynDataTableComponent) dataTable: MynDataTableComponent;
  identifier: string;
  name: string;

  private returnUrl = '/myn';
  constructor(private router: Router,
              private uploadService: UploadService,
              public dialog: MatDialog) { }

  ngOnInit(): void {
    this.uploadService.resetFields();
  }

  openMynEditDetails(identifier: string): void {
    const path = identifier ? '/' + identifier + '/edit' : '/new';
    this.router.navigateByUrl(this.returnUrl + path);
  }

  openDialog(): void {
    // this.dataTable.ngOnInit();
    // todo: maybe implement reload datatable's content before creation & insertion of new item
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = true;
    dialogConfig.panelClass = ['myn-create-dialog-panel'];

    dialogConfig.direction = 'rtl';
    // dialogConfig.width = '800px';
    dialogConfig.data = {identifier: this.identifier, name: this.name};

    const dialogRef = this.dialog.open(MyNusachCreateDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.name = result;
    });
  }
}
