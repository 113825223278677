import {Component, signal} from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import {AuthService} from '../_service/auth.service';
import {AdminService} from '../_service/admin.service';
import {HttpEventType, HttpHeaders, HttpRequest, HttpResponse} from '@angular/common/http';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent {

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );
  isMenuOpen = false;
  title = 'SoSy - Index Verwaltung';
  private http: any;
  private dialog: any;


  constructor(private breakpointObserver: BreakpointObserver,
              private authService: AuthService,
              private adminService: AdminService) {
  }

  downloadJSON(): any {
    // const url = URL + '/analyses/' + id + '/download';
    // const headers = new HttpHeaders().set('Accept', 'application/zip');
    // const req = new HttpRequest('GET', url, {
    //   headers: headers,
    //   responseType: 'blob',
    //   observe: 'response',
    //   reportProgress: true,
    // });
    // // const dialogRef = this.dialog.open(DownloadInProgressDialogComponent);
    // this.http.request(req).subscribe(event => {
    //   if (event.type === HttpEventType.DownloadProgress) {
    //     dialogRef.componentInstance.progress = Math.round(100 * event.loaded / event.total) // download percentage
    //   } else if (event instanceof HttpResponse) {
    //     dialogRef.componentInstance.progress = 100;
    //     this.saveToFileSystem(event, 'application/zip');
    //     dialogRef.close();
    //   }
    // });
    //
    // this.adminService.downloadJSON().subscribe({
    //   next: (data: any) => {
    //     console.log('blocking or not');
    //     const blob = new Blob([data as any], { type: 'application/zip' });
    //     window.location.href = URL.createObjectURL(blob);
    //   }
    // });
  }

  logout(): void{
    this.authService.logout();
  }

  private saveToFileSystem(response: HttpResponse<any>, type: string): void {
    const contentDispositionHeader: string = response.headers.get('Content-Disposition');
    const parts: string[] = contentDispositionHeader.split(';');
    const filename = parts[1].split('=')[1];
    const blob = new Blob([response.body], { type });
    // saveAs(blob, filename);
    // this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
   }
}
