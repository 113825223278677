import {Component} from '@angular/core';
import {FieldType} from '@ngx-formly/core';


@Component({
  styleUrls: ['date.type.component.css'],
  selector: 'formly-field-date',
  template: `
    <mat-form-field class="date-picker-input-field" appearance="outline" style="display: block;">
      <mat-label>{{props.label}}</mat-label>
      <input matInput
             [matDatepicker]="yearPicker"
             [formlyAttributes]="field"
             [value]="this.field.model[this.field.key.toString()]"
             style="display: inline;width: 92.5%">
      <button class="date-clear-btn" *ngIf="model[field.key.toString()] != '' && model[field.key.toString()] != null"  color="warn" mat-icon-button (click)="resetInput()">
        <mat-icon>clear</mat-icon>
      </button>
      <mat-datepicker-toggle matSuffix [for]="yearPicker"></mat-datepicker-toggle>
      <mat-datepicker #yearPicker (yearSelected)="changeYear($event, yearPicker)" startView="multi-year"></mat-datepicker>
      <div class="alert alert-danger" role="alert" *ngIf="showError && formControl.errors">
        <formly-validation-message [field]="field"></formly-validation-message>
      </div>
    </mat-form-field>
  `,
})
export class FormlyDateField extends FieldType {
  constructor() {
    super();
  }
  changeYear(date, ctrl): void {
    const formattedDate = date.format('YYYY-MM-DD');
    this.field.model[this.field.key as string] = formattedDate + 'T00:00:00.000Z';
    ctrl.close();
  }
  resetInput(): void {
    this.field.model[this.field.key as string] = '';
  }
}
