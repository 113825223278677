import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { JwtContainer } from '../_model/jwt-container.model';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private tokenSubject: BehaviorSubject<JwtContainer>;
  public jwtContainer: Observable<JwtContainer>;
  private isLoggedIn = false;
  constructor(private router: Router, private http: HttpClient) {
    this.tokenSubject = new BehaviorSubject<JwtContainer>(JSON.parse(localStorage.getItem('sosy_token') as string));
    this.jwtContainer = this.tokenSubject.asObservable();
  }

  public parseJwt(): any {
    const tokenContainer = this.getToken;
    if (tokenContainer.access_token) {
      const base64Url = tokenContainer.access_token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      return  JSON.parse(jsonPayload);
    }
  }

  public get getToken(): JwtContainer {
    return this.tokenSubject.value;
  }

  changePassword(updatePassword: any): any{
    return this.http.post<any>(`${environment.baseUrl}/accounts/change/pass`, updatePassword)
      .pipe(map(data => {
      }));
  }

  login(username: string, password: string): Observable<JwtContainer> {
    const postBody = { username, password };
    return this.http.post<JwtContainer>(`${environment.baseUrl}/auth/authenticate`, postBody)
      .pipe(map(jwtContainer => {
        this.isLoggedIn = true;
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('sosy_token', JSON.stringify(jwtContainer));
        this.tokenSubject.next(jwtContainer);
        return jwtContainer;
      }));
  }

  logout(): void {
    // remove user from local storage and set current user to null
    localStorage.removeItem('sosy_token');
    // @ts-ignore
    this.tokenSubject.next(null);
    this.router.navigate(['/login']);
  }

  IsLoggedIn(): boolean {
    return this.isLoggedIn;
  }

  getUserRoles(): string[] {
    const user = this.parseJwt();
    return user.rol;
  }
}
