import { Injectable } from '@angular/core';
import { AovContainer } from '../_model/aov/aov-container';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { MyNusachContainer } from '../_model/mynusach/mynusach-container';

@Injectable({
  providedIn: 'root'
})
export class ContainerService {

  private headers: HttpHeaders = new HttpHeaders();
  private options: { headers: HttpHeaders, params?: HttpParams };
  private container: AovContainer | MyNusachContainer;


  public getContainer(): AovContainer | MyNusachContainer{
      return this.container;
  }
  public setContainer(aovContainer: AovContainer | MyNusachContainer): void {
      this.container = aovContainer;
  }

  constructor(private http: HttpClient) {
    this.options = { headers: this.headers.set('content-type', 'application/json') };
  }

  // getAovContainerById(id: any): Observable<AovContainer> {
  //   return this.http.get<AovContainer>(`${environment.baseUrl}/api/admin/aov/` + id);
  // }
  //
  // getAovPage(pageIndex: number, pageSize: number): Observable<any> {
  //   return this.http.get<any>(`${environment.baseUrl}/api/admin/aov?page=${pageIndex}&size=${pageSize}`);
  // }

  hasChanges(dataModel: AovContainer | MyNusachContainer): boolean {
    return JSON.stringify(this.container) !== JSON.stringify(dataModel);
  }
}
