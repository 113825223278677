import {IndexSchema} from './IndexSchema';
import {IndexSchemaSheet} from './sheet/IndexSchemaSheet';

export class IndexSchemaContainer {
  indexSchema: IndexSchema;
  sheetMap: Map<string, IndexSchemaSheet>;

  static fromResponse(data: any): any {
    const result: any = { sheetMap: new  Map<string, Map<string, any>>()};
    data.sort((a, b) => a.orderInfo - b.orderInfo);
    for (let i = 0; i < data.length; i++) {
      const sheet: IndexSchemaSheet = data[i];
      result.indexSchema = sheet.schema;
      result.sheetMap.set(sheet.name, sheet);
    }
    return result;
  }
}
