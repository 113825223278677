import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { JwtContainer } from './_model/jwt-container.model';
import { AuthService } from './_service/auth.service';
import { MenuComponent } from './menu/menu.component';
import { AdminService } from './_service/admin.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  @ViewChild('app-menu')
  public menu!: MenuComponent;
  tokenContainer: JwtContainer | undefined;
  adminService: AdminService;
  constructor(private authService: AuthService, private as: AdminService) {
    this.adminService = as;
    this.authService.jwtContainer.subscribe(x => this.tokenContainer = x);
  }

  ngAfterViewInit(): void{
  }

  logout(): void{
    this.authService.logout();
  }
}
