import {IEntityType} from './IEntityType';

export class Aov implements IEntityType
{
  public typeName = 'aov';
}
export class MyNusach implements IEntityType
{
  public typeName = 'myn';
}
export class Cantor implements IEntityType
{
  public typeName = 'cantor';
}
export class Schema implements IEntityType
{
  public typeName = 'schema';
}
