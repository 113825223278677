export enum CompositionType {
    SONG= 'SONG',
    INTERVIEW= 'INTERVIEW',
    LITURGY = 'LITURGY'
}
export enum IndexType {
    AOV= 'AOV',
    SOSY= 'SOSY',
    MYN= 'MYN'
}
export enum FileType {
    MP3= 'MP3',
    MP4= 'MP4',
    M4A= 'M4A',
    JPG= 'JPG',
    PNG= 'PNG',
    PDF= 'PDF'
}
export enum MediaType {
    AUDIO= 'AUDIO',
    IMG= 'IMG',
    PIANOFILE= 'PIANOFILE',
    SHEETMUSIC= 'SHEETMUSIC',
    VIDEO= 'VIDEO'
}
