import { Component, ViewContainerRef } from '@angular/core';
import {FieldArrayType, FieldType, FieldWrapper} from '@ngx-formly/core';

@Component({
    selector: 'composition-array-type',
    styleUrls: ['./composition-array.type.component.scss'],
    template: `
    <div class="mb-3 array-input-container">
      <legend class="legend" *ngIf="to.label">{{ to.label }}</legend>
      <p *ngIf="props.description">{{ to.description }}</p>
      <div class="alert alert-danger" role="alert" *ngIf="showError && formControl.errors">
        <formly-validation-message [field]="field"></formly-validation-message>
      </div>

      <div *ngFor="let item of field.fieldGroup;let i = index;" class="sosy-list-item row align-items-center">

        <div class="col-2 text-right">
            <mat-accordion style="margin: 4px 16px 0px 0px">
              <mat-expansion-panel style="margin-top:16px;border: 1px solid #000">
                <mat-expansion-panel-header style="padding-left: 0">
                  <mat-panel-title>
                    <div class="col-2 text-right" style="position:relative;margin-left:0px;left:8px;padding-left:0px;">
                      <button mat-icon-button color="warn" type="button" (click)="remove(i)" style="position:relative;left:0px;padding-left:12px;">
                        <mat-icon>remove_circle</mat-icon>
                      </button>
                      <span style="position:relative;top:-0.4rem">{{item.props.label}}{{item.key}}</span>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <formly-field class="col" [field]="item"></formly-field>
              </mat-expansion-panel>
            </mat-accordion>

        </div>
      </div>
      <div class="d-flex flex-row-reverse">
        <button class="add-sosy-list-item-btn" mat-flat-button color="primary" type="button" (click)="add(model)">
          <mat-icon>add_circle</mat-icon>
        </button>
      </div>
    </div>
  `,
})
export class CompositionArrayTypeComponent extends FieldArrayType  {
    _staticContent: ViewContainerRef;
    fieldComponent: ViewContainerRef;
    constructor() {
        super();
    }
    add(i?: number, initialModel?: any, markAsDirty?: { markAsDirty: boolean; }): void {
        super.add();
    }
}
