import { Injectable } from '@angular/core';
import { MediaType } from '../_model/_shared/enums';
import { AlertService } from '../_service/alert.service';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  public imgFileList = [];
  public audioFileList = [];
  public pianoFileList = [];
  public sheetMusicList = [];
  public videoFileList = [];
  public hasDataForUpload(): boolean {
    return (
        this.imgFileList.length > 0
        || this.audioFileList.length > 0
        || this.pianoFileList.length > 0
        || this.sheetMusicList.length > 0
        || this.videoFileList.length > 0
    );
  }

  constructor(private alertService: AlertService) {}

  public resetFields(): void {
    this.imgFileList = [];
    this.audioFileList = [];
    this.pianoFileList = [];
    this.sheetMusicList = [];
    this.videoFileList = [];
  }

  // Audio
  public getUploadAudio(): File[] {
    return this.audioFileList;
  }
  public setUploadAudio(file: File): void {
    this.audioFileList.push(file);
  }
  public replaceUploadAudio(file: File, idx: any): void {
    this.audioFileList[idx] = file;
  }
  // Video
  public getUploadVideo(): File[] {
    return this.videoFileList;
  }
  public setUploadVideo(file: File): void {
    this.videoFileList.push(file);
  }
  public replaceUploadVideo(file: File, idx: any): void {
    this.videoFileList[idx] = file;
  }
  // Image
  public getUploadImageList(): File[] {
    return this.imgFileList;
  }
  public setUploadImage(file: File): void {
    this.imgFileList.push(file);
  }
  public replaceUploadImage(file: File, idx: any): void {
    this.imgFileList[idx] = file;
  }
  // Piano File (Audio)
  public getUploadPianoFile(): File[] {
    return this.pianoFileList;
  }
  public setUploadPianoFile(file: File): void {
    this.pianoFileList.push(file);
  }
  public replaceUploadPianoFile(file: File, idx: any): void {
    this.pianoFileList[idx] = file;
  }
  // Sheet Music (PDF)
  public getUploadSheetMusic(): File[] {
    return this.sheetMusicList;
  }
  public setUploadSheetMusic(file: File): void {
    this.sheetMusicList.push(file);
  }
  public replaceUploadSheetMusic(file: File, idx: any): void {
    this.sheetMusicList[idx] = file;
  }

  public addMediaFileIfNotExist(mediaType: MediaType, file: File): void{
    switch (mediaType) {
      case MediaType.AUDIO:
        this.addIfNotExist(this.audioFileList, file);
        break;
      case MediaType.IMG:
        this.addIfNotExist(this.imgFileList, file);
        break;
      case MediaType.PIANOFILE:
        this.addIfNotExist(this.pianoFileList, file);
        break;
      case MediaType.SHEETMUSIC:
        this.addIfNotExist(this.sheetMusicList, file);
        break;
      case MediaType.VIDEO:
        this.addIfNotExist(this.videoFileList, file);
        break;
    }
  }

  public addIfNotExist(fileList: any[], file: File): void {
    if (0 === fileList.length) {
      fileList.push(file);
    }
    else if (0 < fileList.length) {
      for (let i = 0; i < fileList.length; i++) {
        if (fileList[i].name === file.name){
          fileList[i] = file;
          this.alertService.success('File with filename: ' + file.name + ' succesfully replaced');
          break;
        }
        else if (i === fileList.length - 1){
          fileList.push(file);
        }
      }
    }
  }

  public removeIfExist(fileList, file): void{
    for (let i = 0; i < fileList.length; i++){
      const f = fileList[i];
      if (f.name === file.name){
        fileList[i].splice(i, 1);
        break;
      }
    }
  }

  public getUnionAllFileList(): File[] {
    return this.getUploadImageList()
      .concat(this.getUploadAudio())
      .concat(this.getUploadPianoFile())
      .concat(this.getUploadSheetMusic())
      .concat(this.getUploadVideo());
  }


  public removeMediaFileIfExist(mediaType: MediaType, file: File): void {
    switch (mediaType){
      case MediaType.AUDIO:
        this.removeIfExist(this.audioFileList, file);
        break;
      case MediaType.IMG:
        this.removeIfExist(this.imgFileList, file);
        break;
      case MediaType.PIANOFILE:
        this.removeIfExist(this.pianoFileList, file);
        break;
      case MediaType.SHEETMUSIC:
        this.removeIfExist(this.sheetMusicList, file);
        break;
      case MediaType.VIDEO:
        this.removeIfExist(this.videoFileList, file);
        break;
    }
  }
}
