<!--<div class="mat-elevation-z8">-->
  <table class="full-width-table"
         mat-table
         matSort
         [dataSource]="dataSource"
         (matSortChange)="sortMyData($event)"
         aria-label="Elements">
    <!-- Id Column -->
    <ng-container matColumnDef="identifier">
      <th class="column-header" mat-header-cell *matHeaderCellDef mat-sort-header>Identifier</th>
      <td mat-cell *matCellDef="let row">{{row.person.personBase.identifier}}</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="firstname">
      <th class="column-header" mat-header-cell *matHeaderCellDef mat-sort-header>First Name</th>
      <td mat-cell *matCellDef="let row">{{row.person.personBase.firstName}}</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="lastname">
      <th class="column-header" mat-header-cell *matHeaderCellDef mat-sort-header>Last Name</th>
      <td mat-cell *matCellDef="let row">{{row.person.personBase.lastName}}</td>
    </ng-container>

    <!-- Action Column -->
    <ng-container matColumnDef="action">
      <th class="column-header" mat-header-cell *matHeaderCellDef mat-sort-header>Action</th>
      <td mat-cell *matCellDef="let row">
        <button mat-stroked-button color="primary" matTooltip="Edit {{row.identifier}}" (click)="openPersonDetails(row)">
          <mat-icon class="button-icon">edit</mat-icon>
        </button>
        <button mat-stroked-button
                color="warn"
                matTooltip="Delete {{row.identifier}}"
                (click)="deletePerson(row)"
                style="margin-left: 4px">
          <mat-icon class="button-icon">delete</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <div style="margin: 0 auto">
    <mat-paginator #paginator
                   [length]="totalSize"
                   [pageIndex]="pageNumber"
                   [pageSize]="pageSize"
                   [pageSizeOptions]="[10, 20, 50, 100, 200]"
                   (page)="pageEvent = getServerData($event)"
                   aria-label="Select page" showFirstLastButtons>
    </mat-paginator>
  </div>

<!--</div>-->
