import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard} from '../_utils';
import { DataTableComponent } from './person/data-table/data-table.component';
import { AdminComponent } from './admin.component';
import { AovMasterComponent } from './aov/aov-master/aov-master.component';
import { AovDetailComponent } from './aov/aov-detail/aov-detail.component';
import { SchemaMasterComponent } from './schema/schema-master/schema-master.component';
import { SchemaDetailComponent } from './schema/schema-detail/schema-detail.component';
import { SchemaCreateComponent } from './schema/schema-create/schema-create.component';
import { SchemaDataTableComponent } from './schema/schema-data-table/schema-data-table.component';
import { MynMasterComponent } from './myn/myn-master/myn-master.component';
import { MynDetailComponent } from './myn/myn-detail/myn-detail.component';

const routes: Routes = [
  // { path: '', component: AdminComponent, canActivate:  [AuthGuard] },
  { path: 'schema', component: SchemaMasterComponent, canActivate:  [AuthGuard] },
  { path: 'schema/:id/edit', component: SchemaDetailComponent, canActivate:  [AuthGuard], runGuardsAndResolvers: 'always' },
  { path: 'schema/new', component: SchemaCreateComponent, canActivate:  [AuthGuard] },
  // { path: 'people', component: PersonMasterComponent, canActivate: [AuthGuard] },
  // { path: 'people/:id/edit', component: PersonDetailComponent, canActivate: [AuthGuard] },
  { path: 'aov', component: AovMasterComponent, canActivate:  [AuthGuard] },
  { path: 'aov/:id/edit', component: AovDetailComponent, canActivate:  [AuthGuard] },
  { path: 'aov/new', component: AovDetailComponent, canActivate:  [AuthGuard] },
  { path: 'myn', component: MynMasterComponent, canActivate:  [AuthGuard] },
  { path: 'myn/:id/edit', component: MynDetailComponent, canActivate:  [AuthGuard] },
  { path: 'myn/new', component: MynDetailComponent, canActivate:  [AuthGuard] },
  // otherwise redirect to home
  // { path: '', redirectTo: '/admin/person', pathMatch: 'full' },
  // { path: '**', redirectTo: '/dashboard' }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
export const ArrayOfComponents = [
  AdminComponent,
  // PersonMasterComponent,
  // PersonDetailComponent,
  DataTableComponent,
  SchemaMasterComponent,
  SchemaDetailComponent,
  SchemaCreateComponent,
  SchemaDataTableComponent
];
