<h1 mat-dialog-title>Add New Entry</h1>
  <div mat-dialog-content>
    <p>{{data.identifier}}</p>
    <mat-form-field appearance="fill">
      <mat-label>FirstName</mat-label>
      <input matInput [(ngModel)]="data.firstName">
    </mat-form-field><br>
      <mat-form-field appearance="fill">
          <mat-label>LastName</mat-label>
          <input matInput [(ngModel)]="data.lastName">
      </mat-form-field><br>
  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button (click)="save(data)" cdkFocusInitial>Save</button>
  </div>


