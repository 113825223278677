import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AlertModel, AlertType } from '../_model/_shared/alert.model';

@Injectable({ providedIn: 'root' })
export class AlertService {
  private subject = new Subject<AlertModel>();
  private defaultId = 'default-alert';

  private ERROR_TITLE = ' Es ist ein unerwarteter Fehler aufgetreten.';

  // enable subscribing to alerts observable
  public onAlert(id = this.defaultId): Observable<AlertModel> {
    return this.subject.asObservable().pipe(filter(x => x && x.id === id));
  }

  // convenience methods
  public success(message: string, options?: any): void {
    this.alert(new AlertModel({ ...options, type: AlertType.Success, message }));
  }

  error(errorCode: string, options?: any, placeholderList?: string[]): void {
    const errorMessage: {title: string, message: string} = this.getErrorMessageViaErrorCode(errorCode);
    this.alert(new AlertModel({ ...options, type: AlertType.Error, message: errorMessage.message, title: errorMessage.title }));
  }

  public info(message: string, options?: any): void {
    this.alert(new AlertModel({ ...options, type: AlertType.Info, message, title: this.ERROR_TITLE }));
  }

  public warn(message: string, options?: any): void {
    this.alert(new AlertModel({ ...options, type: AlertType.Warning, message }));
  }

  public confirm(message: string, callback: (row) => void, param: object, options?: any): void {
    this.alert(new AlertModel({ ...options, type: AlertType.Confirm, message, confirmCallback: callback, callbackParam: param }));
  }

  // main alert method
  public alert(alert: AlertModel): void {
    alert.id = alert.id || this.defaultId;
    this.subject.next(alert);
  }

  // clear alerts
  public clear(id = this.defaultId): void {
    this.subject.next(new AlertModel({ id }));
  }

  private getErrorMessageViaErrorCode(errorCode: string): { title: string, message: string } {
      let message = '';
      if (null != errorCode && !errorCode.startsWith('<!doctype html>')){
        message = errorCode;
      }
      const result = { title: 'Es ist ein Fehler aufgetreten', message };
      switch (errorCode)
      {
        case 'EXCEPTION_MESSAGE_CLIENT_ERROR__MYNDETAIL__DELETEMEDIAFILE__FORM_HAS_UNSUBMITTED_CHANGES':
        case 'EXCEPTION_MESSAGE_CLIENT_ERROR__AOVDETAIL__DELETEMEDIAFILE__FORM_HAS_UNSUBMITTED_CHANGES':
          result.message = 'Das Formular enthält ungespeicherte Daten. Bitte vor dem Löschen zunächst speichern da sonst alle Änderungen verloren gehen.';
          break;
        case 'AOV__AOVSERVICE__DELETEMEDIAFILE__FILE_NOT_FOUND':
          result.message = 'Es ist ein Fehler aufgetreten. Die betroffene Datei wurde nicht gefunden und konnte daher nicht gelöscht werden.';
          break;
        case 'AOV__ADMINCLIENT__INITVIEWDATA__GETAOVCONTAINERBYID__AOVCONTAINER_DOES_NOT_EXIST':
          result.message = 'Die Daten konnten nicht geladen werden. Zu der übermittelten ID existiert kein Datensatz im AOV-Index';
          break;
        case 'SERVICE__FILEMANAGER__SAVEFILE__FILE_ALREADY_EXISTS':
          result.message = 'Die übertragenen Daten enthalten mindestens eine Datei, die auf dem Server bereits existiert. Dateien können nicht mehrfach hochgeladen werden. Die Daten wurden nicht gespeichert.';
          break;
        case 'AOV__AOVSERVICE__SAVE__FILE_ALREADY_EXISTS':
          result.message = 'Die übertragenen Daten enthalten mindestens eine Datei, die auf dem Server bereits existiert. Dateien können nicht mehrfach hochgeladen werden. Die Daten wurden nicht gespeichert.';
          break;
        case 'AOV__AOVSERVICE__SAVE__INCONSISTENT_MEDIA_FILE_DATA_DETECTED':
          result.title = 'Inkonsistente Daten gefunden';
          result.message = 'Der Betroffene Eintrag enthält inkonsitente Daten. Die Daten konnten nicht gespeichert werden.';
          break;
        case 'COMMON__DAOBASE__PUBLISHON__UNEXPECTED_ERROR_OCCURED':
          result.message = 'Das indizieren für die öffentliche Suche ist fehlgeschlagen.\n Der Vorgang wurde abgebrochen. Die Daten konnten nicht gespeichert werden.';
          break;
        default:
          result.title = 'Es ist ein unbekannter Fehler aufgetreten';
          if (errorCode.includes('0 Unknown Error') && errorCode.includes('Http failure response')){
            result.title = 'Der Online-Dienst ist nicht erreichbar';
            result.message = 'Dies kann z.B. daran liegen das keine Internetverbindung besteht, oder der entfernte Server offline ist.';
          }
          break;
      }
      return result;
  }
}
