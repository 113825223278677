<div id="aov-master-content">
  <app-aov-data-table></app-aov-data-table>
  <button mat-fab
          color="primary"
          type="submit"
          class=""
          matTooltip="Create New"
          (click)="openDialog()"
          style="position:absolute;right: 16px;top:16px">
    <mat-icon>add</mat-icon>
  </button>
</div>
