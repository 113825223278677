import {Injectable} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {IndexType} from '../_model/_shared/enums';

export enum Events {
  'onDeleteMediaFile',
  'onMediaFileSelected',
  'onAlertError'
  // other events here
}

export class EmitEvent {
  name: Events;
  value: any;
  constructor(name: Events, value: any) {
    this.name = name;
    this.value = value;
  }
}

@Injectable({
  providedIn: 'root'
})
export class EventBusService {
  private subject$ = new Subject();

  emit(event: EmitEvent): void {
    this.subject$.next(event);
  }

  on(event: Events, action: any): Subscription {
    return this.subject$.pipe(
      filter((e: EmitEvent) => e.name == event),
      map((e: EmitEvent) => e.value))
      .subscribe(action);
  }

  getIndexTypeViaFormAction(formAction: string): IndexType {
    let result: IndexType;
    const action = formAction.split('/');
    if (null != action && action.length > 2){
      const tempValue = action[action.length - 2];
      const prefix = tempValue.split('_')[0];
      switch (prefix){
        case 'AOV':
          result = IndexType.AOV;
          break;
        case 'MYN':
          result = IndexType.MYN;
          break;
        case 'SOSY':
          result = IndexType.SOSY;
          break;
      }
    }
    return result;
  }

  getIdentifierViaFormAction(formAction: any): string {
    let result: string;
    const action = formAction.split('/');
    if (null != action && action.length > 2){
      result = action[action.length - 2];
    }
    return result;
  }
}
