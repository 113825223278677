<mat-toolbar class="mat-primary page-toolbar">
  <section class="toolbar-button-container-left" style="margin-left: -16px;">
    <button class="toolbar-button" mat-button routerLink="/schema" routerLinkActive="active-link">
      <mat-icon>account_tree</mat-icon>
      <span>Schema</span>
    </button>
    <button class="toolbar-button" mat-button routerLink="/aov" routerLinkActive="active-link">
      <mat-icon>table_view</mat-icon>
      <span>AOV</span>
    </button>
    <button class="toolbar-button"
            mat-button
            routerLink="/myn"
            routerLinkActive="active-link">
      <mat-icon>table_view</mat-icon>
      <span>My Nusach</span>
    </button>
  </section>
    <button class="toolbar-button" mat-button (click)="downloadJSON()">
        <mat-icon>download</mat-icon>
        <span>Download JSON</span>
    </button>
  <section class="toolbar-button-container-right">


    <button class="toolbar-button" mat-button (click)="logout()">
      <mat-icon>logout</mat-icon>
      <span>Logout</span>
    </button>
  </section>
</mat-toolbar>
