<!--<div class="mat-elevation-z8">-->
<table class="full-width-table"
       mat-table
       matSort
       [dataSource]="dataSource"
       (matSortChange)="sortMyData($event)"
       aria-label="Elements">
  <!-- Id Column -->
  <ng-container matColumnDef="indexSchemaId">
    <th class="column-header" mat-header-cell mat-sort-header *matHeaderCellDef>Identifier</th>
    <td mat-cell *matCellDef="let row">{{row.indexSchemaId}}</td>
  </ng-container>
  <!-- Name Column -->
  <ng-container matColumnDef="name">
    <th class="column-header" mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let row">{{row.name}}</td>
  </ng-container>


  <ng-container matColumnDef="a">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="column-header">  </th>
    <td mat-cell *matCellDef="let row">
    <div style="width: 200px"></div>
    </td>
  </ng-container>

  <!-- Action Column -->
  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="column-header">Action</th>
    <td mat-cell *matCellDef="let row">
      <button mat-stroked-button
              color="primary"
              matTooltip="Edit {{row.indexSchemaId}}"
              (click)="openDetails(row)">
        <mat-icon class="button-icon">edit</mat-icon>
      </button>
      <button mat-stroked-button
              color="warn"
              matTooltip="Delete {{row.name}}"
              (click)="deleteSchema(row)"
              style="margin-left: 4px">
        <mat-icon class="button-icon">delete</mat-icon>
      </button>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<div style="position: relative;margin: 0 auto; top: 64px;">
  <mat-paginator #paginator
                 [length]="totalSize"
                 [pageIndex]="pageNumber"
                 [pageSize]="pageSize"
                 [pageSizeOptions]="[10, 20, 50, 100, 200]"
                 (page)="pageEvent = getServerData($event)"
                 aria-label="Select page" showFirstLastButtons>
  </mat-paginator>
</div>

<!--</div>-->
