import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { AppRoutingModule, ArrayOfComponents} from './app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { AlertModule } from './_alert/alert.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HttpRequestInterceptor } from './_utils';
import { MenuComponent } from './menu/menu.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MAT_DATE_FORMATS, MATERIAL_SANITY_CHECKS } from '@angular/material/core';
import { AngularMaterialModule } from './angular-material.module';
import { AdminModule } from './admin/admin.module';
import { AdminRoutingModule } from './admin/admin-routing.module';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { FormlyMatFormFieldModule } from '@ngx-formly/material/form-field';
import { MatListModule } from '@angular/material/list';
import { InitContextService } from './_service/init.context.service';
import { MatMomentDateModule } from '@angular/material-moment-adapter';

function initializeAppFactory(appContext: InitContextService): () => Promise<any> {
  return () => {
    const promises: Array<Promise<any>> = [];
    promises.push(appContext.initJsonSchema());
    return Promise.all(promises);
  };
}

@NgModule({
  declarations: [
    AppComponent,
    ArrayOfComponents,
    MenuComponent
  ],
    imports: [
        AlertModule,
        AngularMaterialModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        ReactiveFormsModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        }),
        LayoutModule,
        MatToolbarModule,
        FormlyMatFormFieldModule,
        MatSidenavModule,
        MatIconModule,
        MatListModule,
        AdminRoutingModule,
        AdminModule,
        // AppRouting Module should away be the last module to import
        // otherwise it might overwrite routing of submodules that are imported later on
        AppRoutingModule,
        MatMomentDateModule,
    ],
  providers: [
    // { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
    { provide: MATERIAL_SANITY_CHECKS, useValue: false},
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: 'outline'
    },
    // { provide: APP_INITIALIZER, multi: true, useFactory: initializeAppFactory, deps: [InitContextService] },
    { provide: MAT_DATE_FORMATS, useValue: {
        parse: {
          dateInput: ['l', 'LL'],
        },
        display: {
          dateInput: 'YYYY',
          monthYearLabel: 'YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'YYYY',
        },
      },
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
