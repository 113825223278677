<legend *ngIf="to.label">{{ to.label }}</legend>
<input #audioFileInput
       class="file-input"
       type="file"
       accept=".mp3,.m4a,.mp4"
       (change)="setAudioFile($event)">
<button *ngIf="(!model.audioFile || !model.audioFile.identifier)"
        mat-flat-button
        type="button"
        color="primary"
        class="upload-btn"
        (click)="audioFileInput.click()">Choose File
    <mat-icon>attach_file</mat-icon>
</button>
<button *ngIf="model.audioFile && model.audioFile.identifier"
        mat-flat-button
        type="button"
        color="warn"
        class="delete-btn"
        (click)="delete(model.audioFile)">Delete File
    <mat-icon>delete</mat-icon>
</button>
<br>
<mat-form-field *ngIf="model.audioFile" appearance="outline" style="display:block;width:70%">
    <mat-label>{{props.label}}</mat-label>
    <input matInput [value]="model.audioFile.name" (change)="onFileNameChange($event)">
    <div class="alert alert-danger" role="alert" *ngIf="showError && formControl.errors">
        <formly-validation-message [field]="field"></formly-validation-message>
    </div>
</mat-form-field>
<div class="file-upload"
     *ngIf="!model || !model.audioFile || !model.audioFile.name || '' == model.audioFile.name">{{ "No file selected yet." }}
</div>
<mat-divider></mat-divider><br>