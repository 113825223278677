import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutModule } from '@angular/cdk/layout';
import { AdminRoutingModule, ArrayOfComponents } from './admin-routing.module';
import { AngularMaterialModule } from '../angular-material.module';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NullTypeComponent } from '../_type/null.type';
import { ArraySimpleTypeComponent } from '../_type/simple-array/simple-array';
import { ObjectTypeComponent } from '../_type/object.type';
import { MultiSchemaTypeComponent } from '../_type/multischema.type';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyAudioFileField } from '../_type/audio-type/audio-mp3.component';
import { FormlyPianoFileField } from '../_type/piano-file-type/piano-file-pdf.component';
import { FormlySheetMusicFileField } from '../_type/sheet-music-type/sheet-music-pdf.component';
import { FormlyDateField } from '../_type/date-type/date.type.component';
import { FormlyImgFileField } from '../_type/img-type/photo-img.component';
import { FormlyVideoFileField } from '../_type/video-type/video-type.component';
import { FormlyPortraitFileField } from '../_type/portrait-type/portrait-img.component';
import { FormlyFieldDatepicker, FormlyMatDatepickerModule} from '@ngx-formly/material/datepicker';
import { FormlyFieldInput, FormlyMatInputModule} from '@ngx-formly/material/input';

import { AovCreateDialogComponent } from './aov/aov-create-dialog/aov-create.component';
import { AovDetailComponent } from './aov/aov-detail/aov-detail.component';
import { AovMasterComponent } from './aov/aov-master/aov-master.component';
import { AovDataTableComponent } from './aov/aov-data-table/aov-data-table.component';


import {CompositionArrayTypeComponent} from '../_type/composition-array/composition-array.type.component';
import {MynDataTableComponent} from './myn/myn-data-table/myn-data-table.component';
import {MynMasterComponent} from './myn/myn-master/myn-master.component';
import {MynDetailComponent} from './myn/myn-detail/myn-detail.component';
import {MyNusachCreateDialogComponent} from './myn/myn-create-dialog/myn-create.component';

export function minItemsValidationMessage(err: any, field: FormlyFieldConfig): string {
  return `should NOT have fewer than ${field.props.minItems} items`;
}

export function maxItemsValidationMessage(err, field: FormlyFieldConfig): string {
  return `should NOT have more than ${field.props.maxItems} items`;
}

export function minlengthValidationMessage(err, field: FormlyFieldConfig): string {
  return `should NOT be shorter than ${field.templateOptions.minLength} characters`;
}

export function maxlengthValidationMessage(err, field: FormlyFieldConfig): string {
  return `should NOT be longer than ${field.props.maxLength} characters`;
}

export function minValidationMessage(err, field: FormlyFieldConfig): string {
  return `should be >= ${field.props.min}`;
}

export function maxValidationMessage(err, field: FormlyFieldConfig): string {
  return `should be <= ${field.props.max}`;
}

export function multipleOfValidationMessage(err, field: FormlyFieldConfig): string {
  return `should be multiple of ${field.props.step}`;
}

export function exclusiveMinimumValidationMessage(err, field: FormlyFieldConfig): string {
  return `should be > ${field.props.step}`;
}

export function exclusiveMaximumValidationMessage(err, field: FormlyFieldConfig): string {
  return `should be < ${field.props.step}`;
}

export function constValidationMessage(err, field: FormlyFieldConfig): string {
  return `should be equal to constant "${field.props.const}"`;
}

@NgModule({
  declarations: [
    ArrayOfComponents,
    NullTypeComponent,
    ArraySimpleTypeComponent,
    CompositionArrayTypeComponent,
    ObjectTypeComponent,
    MultiSchemaTypeComponent,
    FormlyAudioFileField,
    FormlyPianoFileField,
    FormlyPortraitFileField,
    FormlySheetMusicFileField,
    FormlyVideoFileField,
    FormlyDateField,
    FormlyImgFileField,
    AovDetailComponent,
    AovMasterComponent,
    AovDataTableComponent,
    AovCreateDialogComponent,
    MynDataTableComponent,
    MynMasterComponent,
    MynDetailComponent,
    MyNusachCreateDialogComponent,
  ],
  imports: [
    AdminRoutingModule,
    AngularMaterialModule,
    CommonModule,
    FormlyMaterialModule,
    FormlyMatInputModule,
    FormlyMatDatepickerModule,
    FormlyModule.forRoot({
        extras: {resetFieldOnHide: true, lazyRender: false},
        validationMessages: [
            {name: 'required', message: 'This field is required'},
            {name: 'null', message: 'should be null'},
            {name: 'minLength', message: minlengthValidationMessage},
            {name: 'maxLength', message: maxlengthValidationMessage},
            {name: 'min', message: minValidationMessage},
            {name: 'max', message: maxValidationMessage},
            {name: 'multipleOf', message: multipleOfValidationMessage},
            {name: 'exclusiveMinimum', message: exclusiveMinimumValidationMessage},
            {name: 'exclusiveMaximum', message: exclusiveMaximumValidationMessage},
            {name: 'minItems', message: minItemsValidationMessage},
            {name: 'maxItems', message: maxItemsValidationMessage},
            {name: 'uniqueItems', message: 'should NOT have duplicate items'},
            {name: 'const', message: constValidationMessage},
        ],
        types: [
            {
                name: 'string', component: FormlyFieldInput,
                defaultOptions: {
                  props: {
                        appearance: 'outline',
                    },
                }
            },
            {
                name: 'number',
                extends: 'input',
                defaultOptions: {
                  props: {
                        type: 'number',
                        appearance: 'outline',
                    },
                }
            },
            {
                name: 'integer',
                extends: 'input',
                defaultOptions: {
                  props: {
                        type: 'number',
                        appearance: 'outline',
                    },
                },
            },
            {name: 'boolean', extends: 'checkbox'},
            {name: 'enum', extends: 'select'},
            {name: 'mp3', component: FormlyAudioFileField},
            {name: 'mp4', component: FormlyVideoFileField},
            {name: 'img', component: FormlyImgFileField},
            {name: 'pianofile', component: FormlyPianoFileField},
            {name: 'portrait', component: FormlyPortraitFileField},
            {name: 'sheetmusic', component: FormlySheetMusicFileField},
            {name: 'date', component: FormlyDateField,
              defaultOptions: {
                defaultValue: new Date(),
                props: {
                  datepickerOptions: {
                    startView: 'year'
                  },
                },
              },
            },
            {name: 'datepicker', component: FormlyFieldDatepicker, wrappers: ['form-field']},
            {name: 'null', component: NullTypeComponent, wrappers: ['form-field']},
            {name: 'array', component: ArraySimpleTypeComponent},
            {name: 'composition-array', extends: 'array', component: CompositionArrayTypeComponent},
            {name: 'object', component: ObjectTypeComponent},
            {name: 'multischema', component: MultiSchemaTypeComponent},
        ],
    }),
    // MatToolbarModule,
    FormsModule,
    LayoutModule,
    // MatGridListModule,
    ReactiveFormsModule,
    // MatIconModule,
    // MatButtonModule,
    // MatProgressSpinnerModule,
    // MatTooltipModule
  ]
})
export class AdminModule { }
