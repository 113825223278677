import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AdminService} from '../../../_service/admin.service';
import {CreateAov} from '../../../_model/aov/create-aov';
import {Router} from '@angular/router';

export interface DialogData {
  identifier: string;
  firstName: string;
  lastName: string;
}

@Component({
  selector: 'app-aov-create',
  templateUrl: './aov-create.component.html'
})
export class AovCreateDialogComponent {
  private returnUrl = '/aov';

  constructor(
    private router: Router,
    private adminService: AdminService,
    public dialogRef: MatDialogRef<AovCreateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {}

  save(data: any): void {
    this.adminService.isLoading = true;
    const createAov = new CreateAov();
    createAov.firstName = data.firstName;
    createAov.lastName = data.lastName;

    this.adminService.createAov(createAov).subscribe({
      next: (result) => {
        this.router.navigateByUrl(this.returnUrl + '/' + result.identifier + '/edit');
        this.dialogRef.close();
        console.log(result);
      },
      error: (err) => {
        console.log(err);
        this.adminService.isLoading = false;
      }
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
