import {Component, EventEmitter, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {FieldType} from '@ngx-formly/core';

import {
  DeleteMediaFile,
  SetMediaFile,
} from '../../_model/_shared/media-file';
import {EmitEvent, EventBusService, Events} from '../../_service/event-bus.service';
import {HttpEventType} from '@angular/common/http';
import {Subject} from 'rxjs';
import {AdminService} from '../../_service/admin.service';
import {FileType, MediaType} from "../../_model/_shared/enums";

@Component({
  selector: 'formly-field-portrait',
  styleUrls: ['./portrait-type.scss'],
  template: `
      <div>
        <img *ngIf="!imgSrc" height="150" src='assets/icons/img_placeholder_black.jpg'/>
        <img *ngIf="imgSrc" height="150" src="{{imgSrc}}"/>
        <mat-divider></mat-divider><br>
        <input type="file"
               class="file-input"
               accept=".jpg"
               (change)="setImg($event)" #portraitImageUpload>

        <button *ngIf="!model.identifier" mat-flat-button
                type="button"
                color="primary"
                class="upload-btn"
                matTooltip="Select Files To Upload"
                (click)="portraitImageUpload.click()">Select File
          <mat-icon>attach_file</mat-icon>
        </button>
        <button *ngIf="model && model.identifier" mat-flat-button
                type="button"
                color="warn"
                class="delete-btn"
                (click)="delete(model)">Delete File
          <mat-icon>delete</mat-icon>
        </button>
        <br>
        <div class="file-upload">{{ model.name || "No image selected yet." }}</div>
        <div class="file-upload" *ngIf="!model">{{ "No file selected yet." }}</div>
        <mat-divider></mat-divider>
      </div><br>
  `,
})
export class FormlyPortraitFileField extends FieldType implements OnInit, OnDestroy{
  constructor(public eventService: EventBusService,
              private adminService: AdminService) {
    super();
  }
  private oldFile: File;
  imgSrc: any;
  parentIdentifier: string;

  private _destroyed: Subject<void> = new Subject();


  @Output() onDeleteMediaFile = new EventEmitter<DeleteMediaFile>();
  @Output() onMediaFileSelected = new EventEmitter<SetMediaFile>();

  public ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }
  public ngOnInit(): void {

      console.log(this.field.id);

      if (this.model.identifier && this.model.indexType == 'SOSY' &&  null == this.imgSrc){
        this.adminService.getPersonPortrait( this.model.indexIdentifier, this.model.identifier)
          .subscribe({
            next: (response: any) => {
              if (response.type === HttpEventType.DownloadProgress) {
                console.log(Math.round(100 * response.loaded / response.total));
              }
              if (response.type === HttpEventType.Response) {
                this.setDisplayImage(response.body);
              }
            },
            error: () => {
              this.imgSrc = null;
            }
          });
      }

  }

  alertError(message: string): void {
    this.eventService.emit(new EmitEvent(Events.onAlertError, message));
  }

  delete(mediaFile): void {
    const deleteMediaFile = new DeleteMediaFile(mediaFile, this.oldFile);
    this.eventService.emit(new EmitEvent(Events.onDeleteMediaFile, deleteMediaFile));
  }

  setImg(event): void {
    const file: File = event.target.files[0];
    this.setDisplayImage(file);
    const formAction = event.target.formAction;
    const allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
    if (!allowedExtensions.exec(file.name)){
      this.alertError('Please upload file having extensions .jpeg/.jpg/.png only.');
    }
    else {
      this.model.identifier;
      this.model.description;
      this.model.mediaFileCredit;
      this.model.indexType = this.eventService.getIndexTypeViaFormAction(formAction);
      this.model.indexIdentifier = this.eventService.getIdentifierViaFormAction(formAction);
      this.model.fileType = FileType.JPG;
      this.model.mediaType = MediaType.IMG;
      this.model.originalFileName = file.name;
      this.model.name = file.name;

      const setMediaFile = new SetMediaFile(this.model, file);
      this.eventService.emit(new EmitEvent(Events.onMediaFileSelected, setMediaFile));
      this.oldFile = file;
    }
  }

  setDisplayImage(file: any): void {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = (e) => {
      this.imgSrc = reader.result as string;
      this.field.hide = true;
      this.field.hide = false;
    };
  }
}
