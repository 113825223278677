import { MediaFile } from '../_shared/media-file';
import { Place } from '../_shared/Place';

export class AovBase {
  identifier: string;
  firstName: string;
  lastName: string;
  titleList: string[] = [];
  birthDate: Date;
  dateOfDeath: Date;
  placeOfBirth: Place;
  nativeLanguage: string;
  otherLanguages: string[] = [];
  nationality: string;
  gender: string;
  pronouns: string[] = [];
  bio: string;
  photoList: MediaFile[] = [];

  constructor(identifier: string,
              firstName: string,
              lastName: string,
              titleList: string[],
              birthDate: Date,
              dateOfDeath: Date,
              placeOfBirth: Place,
              nativeLanguage: string,
              otherLanguages: string[],
              nationality: string,
              gender: string,
              pronouns: string[],
              bio: string,
              photoList: MediaFile[]) {
    this.identifier = identifier;
    this.firstName = firstName;
    this.lastName = lastName;
    this.titleList = titleList ?? [];
    this.birthDate = birthDate;
    this.dateOfDeath = dateOfDeath;
    this.placeOfBirth = placeOfBirth;
    this.nativeLanguage = nativeLanguage;
    this.otherLanguages = otherLanguages ?? [];
    this.nationality = nationality;
    this.gender = gender;
    this.pronouns = pronouns ?? [];
    this.bio = bio;
    this.photoList = photoList;
  }

  static fromResponse(aovBase: AovBase): AovBase {
    return new AovBase(
      aovBase.identifier,
      aovBase.firstName,
      aovBase.lastName,
      aovBase.titleList,
      aovBase.birthDate,
      aovBase.dateOfDeath,
      aovBase.placeOfBirth,
      aovBase.nativeLanguage,
      aovBase.otherLanguages,
      aovBase.nationality,
      aovBase.gender,
      aovBase.pronouns,
      aovBase.bio,
      aovBase.photoList
    );
  }
}
