import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-object-type',
  template: `
<!--    <div class="mb-3">-->
<!--      <legend class="legend" *ngIf="props.label">{{ props.label }}</legend>-->
<!--      <mat-divider></mat-divider><br>-->
<!--      <p *ngIf="props.description">{{ props.description }}</p>-->
<!--      <div class="alert alert-danger" role="alert" *ngIf="showError && formControl.errors">-->
<!--        <formly-validation-message [field]="field"></formly-validation-message>-->
<!--      </div>-->
      <formly-field *ngFor="let f of field.fieldGroup" [field]="f"></formly-field>
<!--      <br>-->
<!--    </div>-->
  `,
})
export class ObjectTypeComponent extends FieldType {}
